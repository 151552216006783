import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-line-chart',
    templateUrl: 'line-chart.component.html'
})

export class LineChartComponent implements OnInit {
    @Input()
    chartData: any[];

    @Input()
    chartLabels: any[];

    @Input()
    height: number = 400;

    @Input()
    type: string;

    @Input()
    legend: boolean = false;

    @Input()
    maintainAspectRatio: boolean = false;

    @Input()
    hasXAxis: boolean = true;

    @Input()
    hasYAxis: boolean = true;

    @Input()
    hasXTick: boolean = true;

    @Input()
    hasYTick: boolean = true;

    @Input()
    xAxisTitle: string = '';

    @Input()
    yAxisTitle: string = '';

    chartIsReady = false;

    chartOptions: any;

    public chartColors = [
        { // blue
            backgroundColor: 'transparent',
            borderColor: '#8bfafc',
            pointBackgroundColor: '#8bfafc',
            pointBorderColor: '#8bfafc',
            pointHoverBackgroundColor: '#8bfafc',
            pointHoverBorderColor: '#8bfafc'
        },
        { // red
            backgroundColor: '#f92552',
            borderColor: '#f92552',
            pointBackgroundColor: '#f92552',
            pointBorderColor: '#f92552',
            pointHoverBackgroundColor: '#f92552',
            pointHoverBorderColor: '#f92552'
        }
    ];

    ngOnInit() {
        const chartOptions: any = {
            tooltips: {
                callbacks: {
                    title: function (tooltipItems, data) {
                        if (tooltipItems.length === 0) {
                            return;
                        }
                        let format = 'MMM DD, hh:mm';
                        if (data.labels.length > 1) {
                            if (data.labels[data.labels.length - 1].getTime() - data.labels[0].getTime() > 2 * 24 * 3600 * 1000) {
                                format = 'MMM DD';
                            }
                        }
                        return moment(data.labels[tooltipItems[0].index]).format(format);
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: this.maintainAspectRatio,
            legend: {
                display: this.legend
            },
            scales: {
                xAxes: [{
                    display: this.hasXTick,
                    type: 'time',
                    gridLines: {
                        display: this.hasXAxis
                    }
                }],
                yAxes: [
                    {
                        display: this.hasYTick,
                        gridLines: {
                            display: this.hasYAxis
                        }
                    }
                ]
            }
        };

        if (this.xAxisTitle) {
            chartOptions.scales.xAxes.scaleLabel = {display: true, labelString: this.xAxisTitle};
        }

        if (this.yAxisTitle) {
            chartOptions.scales.yAxes.scaleLabel = {display: true, labelString: this.yAxisTitle};
        }

        this.chartOptions = chartOptions;
        this.chartIsReady = true;
    }
}
