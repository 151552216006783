import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Utl} from '../helpers/utl';
import {UserService} from './user.service';

export interface StepInfo {
    step: number;
    isUserInfoFilled: boolean;
    isVerifySubmitted: boolean;
    isIdentityVerified?: boolean;
    isAddressVerified?: boolean;
    stepUrl?: string;
}

@Injectable()
export class VerificationStepService {
    stepInfo: any = {
        step: 0,
        isUserInfoFilled: false,
        isVerifySubmitted: false,
        isIdentityVerified: false,
        isAddressVerified: false,
    };
    currentStep: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private userService: UserService) {
        this.userService.getCurrentUserProfile()
            .subscribe(
                (user) => {
                    if (user.id) {
                        this.extractStepInfo(user);
                        this.setStepUrl();
                        this.setStep(this.stepInfo);
                    }
                });

    }

    setStep(step: StepInfo): void {
        this.currentStep.next(step);
    }

    getStep(): BehaviorSubject<StepInfo> {
        return this.currentStep;
    }

    extractStepInfo(user: any): void {
        this.stepInfo.isUserInfoFilled = Utl.isUserInfoFilled(user);
        this.stepInfo.isVerifySubmitted = user.kycSubmitted;
        let step = user.kycTier;

        if (user.documents && user.documents.length) {
            for (const doc of user.documents) {
                if (['PASSPORT', 'IDENTITY_CARD'].includes(doc.docType)) {
                    this.stepInfo.isIdentityVerified = true;
                }
                if (['UTILITY_BILL', 'BANK_STATEMENT'].includes(doc.docType)) {
                    this.stepInfo.isAddressVerified = true;
                }
            }
        }

        if (user.kycTier === 2) {
            step = 5;
        } else if (this.stepInfo.isAddressVerified &&
            this.stepInfo.isVerifySubmitted) {
            step = 4;
        } else if (this.stepInfo.isIdentityVerified) {
            step = 3;
        } else if (this.stepInfo.isUserInfoFilled) {
            step = 2;
        } else if (step === 0 &&
            !this.stepInfo.isUserInfoFilled &&
            !this.stepInfo.isIdentityVerified &&
            !this.stepInfo.isAddressVerified) {
            step = 1;
        }
        this.stepInfo.step = step;
    }

    setStepUrl(): void {
        let stepUrl;

        switch (this.stepInfo.step) {
            case 1: {
                stepUrl = '/user/profile/verification/fill-profile';
                break;
            }
            case 2: {
                stepUrl = '/user/profile/verification/verify-identity';
                break;
            }
            case 3: {
                stepUrl = '/user/profile/verification/verify-address';
                break;
            }
            case 4: {
                stepUrl = '/user/profile/verification/status-info/pending';
                break;
            }
            case 5: {
                stepUrl = '/user/profile/verification/status-info/success';
                break;
            }
            default:
                break;
        }

        //TODO shortfix to handle kyc through sumsub
        stepUrl = '/user/profile/verification/verify-identity';

        this.stepInfo.stepUrl = stepUrl;
    }
}
