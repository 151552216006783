import {Component, EventEmitter, Input, Output} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';


@Component({
    selector: 'app-table-base',
    template: ''
})
export class TableBaseComponent {
    @Input()
    public total: number = 100000;
    @Input()
    public page: number = 1;
    @Input()
    public itemsPerPage: number = 10;
    @Input()
    public pageSizeOptions: number[] = [10, 25, 50];
    @Input()
    public items = [];

    @Output()
    public pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

    @Output()
    public gotoFirstPage: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    public onPage(event) {
        this.pageChange.emit(event);
    }

    public toFirstPage() {
        this.gotoFirstPage.emit();
    }
}
