import {NgModule} from '@angular/core';
import {RelativeTimePipe} from './relative-time.pipe';
import {AmountPipe} from './amount.pipe';
import {ToFixedPipe} from './to-fixed.pipe';
import {PricePipe} from './price.pipe';
import {QuantityPipe} from './quantity.pipe';
import {TotalPipe} from './total.pipe';
import {ExcerptPipe} from './excerpt.pipe';
import {ExcerptCenterPipe} from './excerpt-center.pipe';
import {DebugPipe} from './debug.pipe';
import {TickerFilterPipe} from './ticker-filter.pipe';
import {ExplorerPipe} from './explorer.pipe';
import {OrderInfoPipe} from './orderinfo.pipe';
import {SafeHtmlPipe} from './safe-html';
import {WalletFilterPipe} from './wallet-filter.pipe';
import {ReversePipe} from './reverse.pipe';
import {FillOrderPipe} from './fill-orders.pipe';

@NgModule({
    declarations: [
        RelativeTimePipe,
        AmountPipe,
        ToFixedPipe,
        PricePipe,
        QuantityPipe,
        TotalPipe,
        ExcerptPipe,
        ExcerptCenterPipe,
        DebugPipe,
        TickerFilterPipe,
        ExplorerPipe,
        OrderInfoPipe,
        SafeHtmlPipe,
        WalletFilterPipe,
        ReversePipe,
        FillOrderPipe
    ],
    exports: [
        RelativeTimePipe,
        AmountPipe,
        ToFixedPipe,
        PricePipe,
        QuantityPipe,
        TotalPipe,
        ExcerptPipe,
        ExcerptCenterPipe,
        DebugPipe,
        TickerFilterPipe,
        ExplorerPipe,
        OrderInfoPipe,
        SafeHtmlPipe,
        WalletFilterPipe,
        ReversePipe,
        FillOrderPipe
    ]
})
export class CommonPipesModule {
}
