import {Pipe, PipeTransform} from '@angular/core';
import {Utl} from '../../helpers/utl';

@Pipe({name: 'quantity'})
export class QuantityPipe implements PipeTransform {


    transform(value: any, symbol: string) {
        return Utl.formatQuantity(symbol, value);
    }
}
