import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'openInExplorer'})
export class ExplorerPipe implements PipeTransform {
    public externalAddresses = {
        BTC:  'https://www.blockchain.com/en/btc/tx/{{transactionId}}',
        ETH:  'https://www.blockchain.com/en/eth/tx/{{transactionId}}',
        XRP:  'https://bithomp.com/explorer/{{transactionId}}',
        BCH:  'https://explorer.bitcoin.com/bch/tx/{{transactionId}}',
        BSV: 'https://blockchair.com/bitcoin-sv/transaction/{{transactionId}}',
        BTG: 'https://btgexplorer.com/tx/{{transactionId}}',
        LTC:  'https://live.blockcypher.com/ltc/tx/{{transactionId}}',
        DOGE: 'https://live.blockcypher.com/doge/tx/{{transactionId}}',
        DASH: 'https://live.blockcypher.com/dash/tx/{{transactionId}}',
        USDT: 'https://omniexplorer.info/tx/{{transactionId}}',
        USDC: 'https://etherscan.io/tx/{{transactionId}}',
        EOS:  'https://etherscan.io/tx/{{transactionId}}',
        XLM: 'https://stellarchain.io/tx/{{transactionId}}',
        GTPLUS: 'https://stellar.expert/explorer/public/asset/{{transactionId}}',
        GTFTA: 'https://stellar.expert/explorer/public/asset/{{transactionId}}'
    };

    transform(transactionId: string, coin: string): any {
        return this.getExplorerLink(coin, transactionId);

    }

    getExplorerLink(coin, transactionId) {
        if (this.externalAddresses[coin]) {
            return this.externalAddresses[coin].replace('{{transactionId}}', transactionId);
        }
        return '#';
    }


}
