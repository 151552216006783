<div *ngIf="chartIsReady" fxFlex="100">
    <canvas fxFlex="100"
            baseChart
            class="m-0"
            [datasets]="chartData"
            [labels]="chartLabels"
            [options]="chartOptions"
            [colors]="chartColors"
            [chartType]="'line'"
            [legend]="legend">
    </canvas>
</div>
