<div class="pass-strength-wrapper">
    <div class="pass-strength-bullets strength-{{passwordStrengthIndicator}}">
        <div class="strength-bullet"
             [class.active]="passwordToCheck.length && passwordStrengthIndicator >= 1"></div>
        <div class="strength-bullet"
             [class.active]="passwordStrengthIndicator >= 2"></div>
        <div class="strength-bullet"
             [class.active]="passwordStrengthIndicator > 2"></div>
        <div class="strength-bullet"
             [class.active]="passwordStrengthIndicator > 3"></div>
        <div class="strength-bullet"
             [class.active]="passwordStrengthIndicator > 4"></div>
    </div>
    <div class="pass-strength-text-box">
        <p *ngIf="showStepText" class="strength-text strength-{{passwordStrengthIndicator}}">
            {{ 'SESSIONS.PASSWORD_STRENGTH' | translate }} -
            <span>
                {{ 'SESSIONS.STRENGTHS.' + strengths[passwordStrengthIndicator - 1] | translate }}
            </span>
        </p>
        <p *ngIf="explanation" class="strength-text">{{explanation}}</p>
    </div>
</div>
