<div class="bb-mat-dialog-container">
    <div class="bb-mat-dialog-header">
        <div class="bb-mat-dialog-title">{{'WARNING' | translate}}</div>
        <div class="bb-mat-dialog-close">
            <span class="material-icons" (click)="cancel()">close</span>
        </div>
    </div>

    <div class="bb-mat-dialog-body">
        {{"MODALS.PROVIDE_ADDITIONAL_TAG" | translate}}
    </div>

    <div class="bb-mat-dialog-footer">
        <button mat-button mat-raised-button
                class="btn btn-basic"
                (click)="cancel()">
            {{"MODALS.CANCEL" | translate}}
        </button>

        <button mat-button mat-raised-button
                class="btn btn-accent"
                (click)="confirm()">
            {{"MODALS.CONFIRM" | translate}}
        </button>
    </div>

</div>
