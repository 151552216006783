import {Component, Inject} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

interface SnackbarData {
    type: 'INFO' | 'SUCCESS' | 'ERROR';
    iconType: 'INFO' | 'SUCCESS';
    title: string;
    text: string;
    [prop: string]: string;
}

@Component({
    selector: 'app-bb-snackbar',
    templateUrl: 'bb-snackbar.component.html',
})
export class BBSnackbarComponent {
    infoData: any = [];

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
                public snackBarRef: MatSnackBar,
                public translate: TranslateService) {
        this.extractData();
    }

    extractData(): void {

        if (!this.data) {
            return;
        }

        const infoList = Object.keys(this.data);
        for (const info of infoList) {
            if (!['type', 'title', 'text'].includes(info)) {
                const attr = {
                    title: info,
                    value: this.data[info]
                };
                this.infoData.push(attr);
            }

            if (info === 'text') {
                const attr = {};

                if (this.data['type'] === 'ERROR') {
                    attr['title'] = this.data['type'];
                    attr['value'] = this.data[info].toString().replace(/Error: /i, '');
                } else {
                    attr['value'] = this.data[info];
                }

                this.infoData.push(attr);
            }
        }
        this.data.iconType = this.data.type === 'ERROR' ? 'INFO' : this.data.type;
    }

    close(): void {
        this.snackBarRef.dismiss();
    }
}
