import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class TFAService {
    verifyNavigateUrl: string;
    disableTriggered: boolean;
    private hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    generate(): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/tfa/generate`, {});
    }

    verify(token): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/tfa/verify`, {token: token});
    }

    disable(token): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/tfa/disable`, {token: token});
    }

    disableForUser(userId): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/tfa/disable/${userId}`, {});
    }    

    skip(): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/tfa/skip`, {});
    }
}
