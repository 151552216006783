<div class="bb-tooltip"
     *ngIf="(tooltipData.text || tooltipData.textArray.length) && tooltipData.show === 'show'"
     [ngClass]="tooltipData?.className" @tooltip>
    <p class="tooltip-title" *ngIf="tooltipData.title">{{tooltipData.title | translate}}</p>
    <p class="tooltip-text" *ngIf="tooltipData.text" [innerHTML]="tooltipData.text | translate"></p>
    <div class="flex layout-all-row-wrap" *ngIf="tooltipData.textArray.length">
        <p *ngFor="let td of tooltipData.textArray"
           class="tooltip-text text-margin" [innerHTML]="td.text | translate : td.data"></p>
    </div>
</div>
