import {Component, Input, OnInit} from '@angular/core';
import {MenuService} from '../../services/menu.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-bb-control-top-panel',
  templateUrl: './bb-control-top-panel.component.html'
})
export class BBControlTopPanelComponent implements OnInit {
    @Input() title: string = '';
    @Input() classNames: string = '';
    @Input() buttonsList: any[] = [];
    @Input() backButton: any = {};

  constructor(public menuService: MenuService, public translate: TranslateService) { }

  ngOnInit(): void {
      if (this.buttonsList.length) {
          for (const btn of this.buttonsList) {
              btn.tooltip = {
                  overlayClassName: 'bottom-aligned-overlay control-top-panel-overlay'
              };
              btn.tooltip.text = btn.title ? this.translate.instant(btn.title) : '';
              btn.tooltip.content = btn.content ? btn.content : '';
              btn.tooltip.iconCode = btn.iconCode ? btn.iconCode : '';
          }
      }
  }

}
