import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';

@Injectable()
export class TvService {
    private hostUrl: string;

    constructor(private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getConfigs(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/tradeview/config`);
    }

    getSymbolConfigs(symbol: string): Observable<any> {
        const symbolParam = symbol.replace('/', '-');

        return this.networkHelper.get(`${this.hostUrl}/tradeview/symbols`, {
            symbol: symbolParam
        });
    }

    getHistory(symbol: string, resolution: string, from: number, to: number): Observable<any> {
        const symbolParam = symbol.replace('/', '-');
        return this.networkHelper.get(`${this.hostUrl}/tradeview/history`, {
            symbol: symbolParam,
            resolution: resolution,
            from: from,
            to: to
        });
    }

    getServerTime(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/tradeview/time`);
    }
}
