<mat-form-field fxFlex="100" *ngIf="itemsArray && itemsArray.length">
    <mat-select
           [formControl]="itemsCtrl"
        placeholder="{{ translationString | translate}}"
        (selectionChange)="selectionChange()">
        <ngx-mat-select-search class="select-search-container"
                               placeholderLabel="{{ translationString | translate}}"
                               noEntriesFoundLabel = "{{'TRANSACTIONS.NO_DATA_FOUND' | translate}}"
                               [formControl]="itemsFilterCtrl">
        </ngx-mat-select-search>
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
            {{item | uppercase | translate}}
        </mat-option>
    </mat-select>
</mat-form-field>
