import {Injectable} from '@angular/core';

@Injectable()
export class MenuService {
    menuElement: any;

    constructor() {

    }

    setMenuElement(menuElement: any): void {
        this.menuElement = menuElement;
    }

    toggle(): void {
        if (this.menuElement) {
            this.menuElement.toggle();
        }
    }

}
