import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ChartingLibraryWidgetOptions,
    ErrorCallback,
    HistoryCallback,
    IDatafeedChartApi,
    IExternalDatafeed,
    LanguageCode,
    LibrarySymbolInfo,
    OnReadyCallback,
    ResolutionString,
    ResolveCallback,
    SearchSymbolsCallback,
    SubscribeBarsCallback,
    widget
} from '../../../assets/charting_library/charting_library.min';
import {environment} from '../../../environments/environment';
import {TvService} from '../../services/tv.service';
import {WsMainService} from '../../services/wsMain.service';
import {Subscription} from 'rxjs/Subscription';
import {StorageService} from '../../services/storage.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ThemeService} from '../../services/theme.service';
import {Router} from '@angular/router';
import 'rxjs-compat/add/operator/distinctUntilChanged';

class TVDataFeed implements IDatafeedChartApi, IExternalDatafeed {

    private candleSubscription: Subscription;

    static getTimezone() {
        const userDate = new Date();
        const userTimeZoneOffset = userDate.getTimezoneOffset();
        return -1 * (userTimeZoneOffset / 60);
    }

    static resolutionToInterval(resolution, defaultVal) {
        switch (resolution) {
            case '1':
                return 'M1';
            case '3':
                return 'M3';
            case '15':
                return 'M15';
            case '30':
                return 'M30';
            case '60':
                return 'H1';
            case 'D':
                return 'D1';
            case 'W':
                return 'W1';
            case 'M':
                return 'M1';
            default:
                if (defaultVal) {
                    return defaultVal;
                }
        }
    }

    constructor(private tvService: TvService,
                private wsMain: WsMainService) {
    }

    public destruct() {
        if (this.candleSubscription) {
            this.candleSubscription.unsubscribe();
        }
    }

    onReady(callback: OnReadyCallback): void {
        this.tvService.getConfigs().subscribe(
            (result) => {
                callback(result.result);
            },
            (err) => {
                console.log(err);
                console.log('Unable to get configs for TradingView.');
            }
        );
    }

    searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {

    }

    resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void {
        this.tvService.getSymbolConfigs(symbolName).subscribe(
            (result) => {
                onResolve(result.result);
            },
            (err) => {
                onError(err);
            }
        );
    }

    convertCandle(candleData) {
        const result = [];
        if (candleData.c.length !== candleData.h.length ||
            candleData.c.length !== candleData.l.length ||
            candleData.c.length !== candleData.o.length ||
            candleData.c.length !== candleData.t.length ||
            candleData.c.length !== candleData.v.length) {
            return result;
        }

        const realTimeZone = TVDataFeed.getTimezone();

        for (let i = 0; i < candleData.c.length; ++i) {
            const timestamp = (candleData.t[i] + realTimeZone * 60 * 60) * 1000;

            result.push({
                time: timestamp,
                low: Number(candleData.l[i]),
                high: Number(candleData.h[i]),
                open: Number(candleData.o[i]),
                close: Number(candleData.c[i]),
                volume: Number(candleData.v[i])
            });
        }

        return result;
    }

    getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, rangeStartDate: number,
            rangeEndDate: number, onResult: HistoryCallback, onError: ErrorCallback, isFirstCall: boolean): void {
        this.tvService.getHistory(symbolInfo.name, resolution, rangeStartDate, rangeEndDate).subscribe(
            (result) => {
                if (result.result && result.result.c && result.result.c.length) {
                    onResult(this.convertCandle(result.result), {noData: false});
                } else {
                    onResult([], {noData: true});
                }
            },
            (err) => {
                onError(err);
            }
        );
    }

    subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback,
                  listenerGuid: string, onResetCacheNeededCallback: () => void): void {
        localStorage.setItem('chartResolution', resolution);

        this.candleSubscription = this.wsMain.initSubscriptions(
            {
                subscribe: 'subscribeCandles',
                unsubscribe: 'unsubscribeCandles',
                events: ['newCandle', 'openCandle']
            },
            {
                symbol: symbolInfo.name,
                limit: 1000,
                interval: TVDataFeed.resolutionToInterval(resolution, 'M15')
            }).subscribe(msg => {
            if (msg.method === 'newCandle' || msg.method === 'openCandle') {
                if (parseFloat(msg.result.min) === 0 && parseFloat(msg.result.max) === 0 && parseFloat(msg.result.volume) === 0) {
                    return;
                }

                const realTimeZone = TVDataFeed.getTimezone();
                const timestamp = new Date(msg.result.timestamp).getTime() + realTimeZone * 60 * 60 * 1000;

                const modifiedRes = {
                    time: timestamp,
                    low: msg.result.min,
                    high: msg.result.max,
                    open: msg.result.open,
                    close: msg.result.close,
                    volume: msg.result.volume
                };
                onTick(modifiedRes);
            }
        });
    }

    unsubscribeBars(listenerGuid: string): void {

    }

    getServerTime(cb): void {
        this.tvService.getServerTime().subscribe(
            (result) => {
                cb(result.result);
            },
            (err) => {
                console.log(err);
            }
        );
    }
}

@Component({
    selector: 'app-tv-chart-container',
    templateUrl: './tv-chart-container.component.html',
    styleUrls: ['./tv-chart-container.component.css']
})
export class TvChartContainerComponent implements OnInit, OnDestroy {
    env = environment;
    tvWidget = null;
    private dataFeed = null;
    private themeSubscription: Subscription;

    // private _datafeedUrl = 'https://demo_feed.tradingview.com';
    private _datafeedUrl = `${this.env.host}/tradeview`;

    constructor(private tvService: TvService,
                private wsMain: WsMainService,
                private storage: StorageService,
                private translate: TranslateService,
                private themeService: ThemeService,
                protected router: Router) {
    }

    @Input()
    set datafeedUrl(dfUrl: string) {
        this._datafeedUrl = dfUrl || this._datafeedUrl;
    }

    private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'AAPL';

    @Input()
    set symbol(symbol: ChartingLibraryWidgetOptions['symbol']) {
        symbol = symbol.replace('/', '-');
        this._symbol = symbol || this._symbol;
    }

    private _interval: ChartingLibraryWidgetOptions['interval'] = '15';

    @Input()
    set interval(interval: ChartingLibraryWidgetOptions['interval']) {
        this._interval = interval || this._interval;
    }

    private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '/assets/charting_library/';

    @Input()
    set libraryPath(libraryPath: ChartingLibraryWidgetOptions['library_path']) {
        this._libraryPath = libraryPath || this._libraryPath;
    }

    private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';

    @Input()
    set chartsStorageUrl(chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']) {
        this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
    }

    private _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] = '1.1';

    @Input()
    set chartsStorageApiVersion(chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']) {
        this._chartsStorageApiVersion = chartsStorageApiVersion || this._chartsStorageApiVersion;
    }

    private _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';

    @Input()
    set clientId(clientId: ChartingLibraryWidgetOptions['client_id']) {
        this._clientId = clientId || this._clientId;
    }

    private _userId: ChartingLibraryWidgetOptions['user_id'] = 'public_user_id';

    @Input()
    set userId(userId: ChartingLibraryWidgetOptions['user_id']) {
        this._userId = userId || this._userId;
    }

    private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = true;

    @Input()
    set fullscreen(fullscreen: ChartingLibraryWidgetOptions['fullscreen']) {
        this._fullscreen = fullscreen || this._fullscreen;
    }

    private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;

    @Input()
    set autosize(autosize: ChartingLibraryWidgetOptions['autosize']) {
        this._autosize = autosize || this._autosize;
    }

    private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';

    @Input()
    set containerId(containerId: ChartingLibraryWidgetOptions['container_id']) {
        this._containerId = containerId || this._containerId;
    }

    mapLanguage(lang) {
        switch (lang) {
            case 'cn':
                return 'zh';
            case 'jp':
                return 'ja';
            case 'kr':
                return 'ko';
            case 'vn':
                return 'vi';
            default: 
                return lang;
        }
    }

    ngOnInit() {
        if (localStorage.getItem('chartResolution')) {
            this._interval = localStorage.getItem('chartResolution');
        }

        if (!this._interval) {
            this._interval = '1';
        }

        const theme = this.storage.getItem('theme') === 'dark' ? 'Dark' : 'Light';



        this.dataFeed = new TVDataFeed(this.tvService, this.wsMain);

        const lang = this.mapLanguage((this.storage.getItem('lang') ? this.storage.getItem('lang') : 'en')) as LanguageCode;

        const widgetOptions = {
            symbol: this._symbol,
            datafeed: this.dataFeed,
            interval: this._interval,
            container_id: this._containerId,
            library_path: this._libraryPath,
            locale: lang,
            disabled_features: ['use_localstorage_for_settings'],
            enabled_features: [/*'study_templates'*/],
            charts_storage_url: this._chartsStorageUrl,
            charts_storage_api_version: this._chartsStorageApiVersion,
            client_id: this._clientId,
            user_id: this._userId,
            fullscreen: this._fullscreen,
            autosize: this._autosize,
            theme: theme,
            // overrides: {
            //     'paneProperties.background': '#ffffff',
            //     'paneProperties.vertGridProperties.color': '#495057',
            //     'paneProperties.horzGridProperties.color': '#495057',
            //     'symbolWatermarkProperties.transparency': 10,
            //     'scalesProperties.textColor': '#495057'
            // }
        };

        if (this.tvWidget) {
            this.tvWidget.setSymbol(this._symbol);
        }

        this.themeSubscription = this.themeService.getTheme()
            .debounceTime(1000)
            .distinctUntilChanged()
            .subscribe((result) => {
                this.doTheming(result, widgetOptions);
            });
    }

    doTheming(themes: any, options: any) {
        const newClassName = `theme-${themes.newTheme}`;
        const oldClassName = `theme-${themes.oldTheme}`;

        if (themes.newTheme) {
            if (themes.newTheme === 'light') {
                options.overrides = {
                    'paneProperties.background': '#ffffff',
                    'paneProperties.vertGridProperties.color': '#F8F8F8',
                    'paneProperties.horzGridProperties.color': '#F8F8F8',
                    'symbolWatermarkProperties.transparency': 10,
                    'scalesProperties.textColor': '#495057'
                };
            }
            if (themes.newTheme === 'dark') {
                options.overrides = {
                    'paneProperties.background': '#262a36',
                    'paneProperties.vertGridProperties.color': '#1e2830',
                    'paneProperties.horzGridProperties.color': '#1e2830',
                    'symbolWatermarkProperties.transparency': 10,
                    'scalesProperties.textColor': '#adadad'
                };
            }

            this.tvWidget = new widget(options);
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                 const langCode = this.mapLanguage(event.lang) as LanguageCode;
                 this.tvWidget.setLanguage(langCode);
            });
        }
    }

    // private themeChart(themes: any, oldClassName: string, newClassName: string) {
    //     const iframe = document.querySelector('iframe[id^="tradingview"]');
    //     if (!iframe) {
    //         return;
    //     }

    //     const doc = iframe['contentWindow'].document;
    //     const iframeDoc = doc.getElementsByTagName('html')[0];
    //     const iframeBody = doc.body;
    //     const controlsBar = doc.getElementsByClassName('chart-controls-bar')[0];

    //     if (themes.newTheme === 'light') {
    //         if (iframeDoc) {
    //             iframeDoc.style.background = '#ffffff';
    //         }
    //         if (iframeBody) {
    //             iframeBody.style.background = '#ffffff';
    //         }
    //         if (controlsBar) {
    //             controlsBar.style.background = '#ffffff';
    //         }
    //     } else {
    //         if (iframeDoc) {
    //             iframeDoc.style.background = '#243748';
    //         }
    //         if (iframeBody) {
    //             iframeBody.style.background = '#243748';
    //         }
    //         if (controlsBar) {
    //             controlsBar.style.background = '#243748';
    //         }
    //     }

    //     if (themes.oldTheme && iframeDoc && iframeDoc.classList.contains(oldClassName)) {
    //         iframeDoc.classList.remove(oldClassName);
    //     }
    //     if (!themes.oldTheme && iframeDoc) {
    //         iframeDoc.classList.remove('theme-dark');
    //     }

    //     if (iframeDoc) {
    //         iframeDoc.classList.add(newClassName);
    //     }
    // }

    ngOnDestroy() {
        if (this.dataFeed) {
            this.dataFeed.destruct();
        }
        if (this.themeSubscription) {
            this.themeSubscription.unsubscribe();
        }
    }
}
