import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class MaintenanceService {
    public hostUrl: string;

    maintenanceChange = new EventEmitter();
    maintenanceData: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    updateConfiguration(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/maintenance`, data);
    }

    getAll(page: number, itemsPerPage: number, status: string, keyword: string): Observable<any> {
        const data = {
            page: page,
            itemsPerPage: itemsPerPage,
            status: status,
            keyword: keyword
        };
        return this.networkHelper.get(`${this.hostUrl}/maintenance/all`, data);
    }

    getMaintenance(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/maintenance/status`);
    }

    getMaintenanceById(id): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/maintenance/${id}`);
    }
}
