import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Utl} from '../../helpers/utl';

export interface SlideToggleInterface {
    icon?: string;
    value: any;
    name?: string;
}

@Component({
    selector: 'app-bb-slide-toggle',
    templateUrl: './bb-slide-toggle.template.html'
})

export class BBSlideToggleComponent implements OnInit {

    _togglers: SlideToggleInterface[] = [];
    _checked: boolean = false;
    _toggled: any;
    _toggledObj: any = {};

    @Input() classNames: string = '';
    @Input() label: string = '';
    @Input() disabled: boolean;
    @Input() set checked(value: any) {
        if (!Utl.isNullOrUndefined(value)) {
            this._toggled = value;
            this.setChecked();
        }
    }

    @Input() set togglers(items: SlideToggleInterface[]) {
        if (items) {
            this._togglers = [...items];
        }
    }

    @Output()onCheck: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        if (!this._toggled) {
            this._toggled = this._togglers[0].value;
        }
        this.setChecked();
    }

    setChecked(): void {
        this._checked = this._toggled !== this._togglers[0].value;
        this._toggledObj = this._toggled === this._togglers[0].value ?
            this._togglers[0] : this._togglers[1];
    }

    onSlideToggle(event: any): void {
        this._toggledObj = event.checked ? this._togglers[1] : this._togglers[0];
        this._toggled = this._toggledObj.value;
        this.onCheck.emit(this._toggled);
    }
}
