<div class="control-top-panel-container box shadowed"
     [ngClass]="classNames">
    <div *ngIf="!backButton.link"
         class="nav-toggler-box">
        <div class="menu-toggler-wrapper">
            <button mat-raised-button
                    class="hamburger-button"
                    (click)="menuService.toggle()">
                <span class="hamburger"></span>
                <span class="hamburger"></span>
                <span class="hamburger"></span>
            </button>
        </div>
    </div>
    <div class="box-header no_margin">
        <h4 *ngIf="title" class="box-header-title">{{title | translate}}</h4>
        <button *ngIf="backButton.text"
                mat-button mat-raised-button
                class="btn btn-basic btn-back"
                [routerLink]="[backButton.link]">
            <span class="material-icons">
                keyboard_backspace
            </span>
            <span class="btn-text">{{backButton.text | translate}}</span>
        </button>
        <div class="control-panel-actions-container">
            <div *ngIf="buttonsList.length"
                 class="control-panel-buttons-box">
                <div *ngFor="let btn of buttonsList"
                     class="control-action-wrapper">
                    <button mat-button mat-raised-button
                            class="btn-control"
                            [ngClass]="btn['classname'] ? 'btn btn-basic' : 'btn btn-basic'"
                            (click)="btn.action()">
                        <span *ngIf="btn.title" class="text">{{btn.title | translate}}</span>
                        <span *ngIf="btn.content" [innerHtml]="btn.content"></span>
                        <span *ngIf="btn.iconCode" class="icon" [ngClass]="btn.iconCode"
                              [appBBTooltip]="btn.tooltip"></span>
                    </button>
                </div>
            </div>

            <div class="control-action-wrapper theme-change">
                <app-bb-theme-color></app-bb-theme-color>
            </div>

            <div class="control-action-wrapper profile-menu">
                <app-bb-menu [classNames]="'hide-info-sm'"></app-bb-menu>
            </div>
        </div>
    </div>
</div>
