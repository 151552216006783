<div class="radio-group-wrapper router-group-wrapper"
     [ngClass]="classNames">
    <mat-radio-group
        class="radio-group"
        [(ngModel)]="_active">
        <mat-radio-button
            class="radio-button"
            *ngFor="let link of _routes"
            [value]="link.path"
            [routerLink]="[base + '/' + link.path]"
            [ngClass]="{'button-active': (link.activeList && link.activeList.includes(_url)) ||
                        (base +'/'+ link.path === _url)}">
                <span class="radio-label-content"
                      [ngClass]="{'icon-inside': link.icon}">
                    <span class="radio-label-text">{{link.name | translate}}</span>
                    <span *ngIf="link.icon" class="radio-icon-wrapper">
                        <app-bb-info-tooltip
                            *ngIf="link.icon"
                            [classNames]="link.icon.classNames"
                            [sign]="link.icon.sign"
                            [tooltipData]="link.tooltipData">
                        </app-bb-info-tooltip>
                    </span>
                </span>
        </mat-radio-button>
    </mat-radio-group>
    <button mat-button
            class="router-trigger"
            [matMenuTriggerFor]="routerMenu">
        {{_selectedName | translate}}
    </button>
    <mat-menu #routerMenu="matMenu">
        <span mat-menu-item
              class="router-list-item"
              *ngFor="let link of _routes">
              <span class="router-list-item-inner"
                    [routerLink]="[base + '/' + link.path]"
                    routerLinkActive="router-active"
                    (click)="onRouteSelect(link)">
                        {{link.name | translate}}
                    <app-bb-info-tooltip
                        *ngIf="link.icon"
                        [classNames]="link.icon.classNames"
                        [sign]="link.icon.sign"
                        [tooltipData]="link.tooltipData">
                    </app-bb-info-tooltip>
            </span>
        </span>
    </mat-menu>
</div>
