<div class="dialog-container fx-all-100 layout-all-col-nowrap">

    <div class="content-box fx-all-100 layout-all-row-wrap">
        <div class="fx-all-100 layout-all-row-nowrap">
            <div class="fx-all-100">
                <mat-form-field class="example-full-width" fxFlex="100">
                    <textarea matInput placeholder='{{"MODALS.REJECT_REASON" | translate}}'
                              [(ngModel)]="reasonText"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="actions-box fx-all-100 layout-all-row-nowrap align-all-fxEnd-fxStr">
        <div class="fx fill-0-1">
            <button mat-raised-button class="approve-button-filled shadowed tall-button" (click)="confirm()">
                {{"MODALS.CONFIRM" | translate}}
            </button>
        </div>
        <div class="fx fill-0-1 offset-all-1">
            <button mat-raised-button class="reject-button-filled shadowed tall-button" (click)="cancel()">
                {{"MODALS.CANCEL" | translate}}
            </button>
        </div>
    </div>

</div>
