import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-header.component.html'
})
export class UserHeaderComponent implements OnInit {
    // todo do we need this component
    @Input() isMobile: boolean = false;
    @Input() isAuthenticated: boolean = false;
    items = [];
    itemsArray = [
        {
            'link': '/',
            'materialicon': 'swap_calls',
            'name': 'USER.TRADING',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/wallets',
            'materialicon': 'account_balance_wallet',
            'name': 'USER.WALLETS',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/trades',
            'materialicon': 'swap_vertical_circle',
            'name': 'USER.TRADES',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/history',
            'materialicon': 'history',
            'name': 'USER.HISTORY',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/open-orders',
            'materialicon': 'access_time',
            'name': 'USER.OPEN_ORDERS',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/transactions',
            'materialicon': 'list_alt',
            'name': 'USER.TRANSACTIONS',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/affiliate',
            'materialicon': 'people_outline',
            'name': 'USER.AFFILIATE',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/position-history',
            'materialicon': 'history',
            'name': 'USER.POSITION_HISTORY',
            'exact': true,
            'enabled': environment.marginTrading.enabled
        },
        {
            'link': '/mt5',
            'materialicon': 'swap_vertical_circle',
            'name': 'USER.MT5',
            'exact': true,
            'enabled': environment.mt5.enabled
        }
    ];

    marketViewPage = false;
    marketViewItems = [
        {
            'link': '/sessions/signin',
            'name': 'SESSIONS.SIGN_IN',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/sessions/signup',
            'name': 'SESSIONS.SIGN_UP',
            'exact': true,
            'enabled': true
        },
    ];

    constructor(private router: Router) {
        for (const item of this.itemsArray) {
            if (item.enabled) {
                this.items.push(item);
            }
        }
    }

    ngOnInit() {
		if (this.router.url.includes('/sessions/market-view')) {
            this.marketViewPage = true;
        }
    }

}
