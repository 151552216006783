import {Component, HostListener, OnInit, Input, ViewChild, AfterViewInit} from '@angular/core';
import { environment } from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MenuService} from '../../../services/menu.service';
import {StorageService} from '../../../services/storage.service';
import {LanguageService} from '../../../services/language.service';
import {MaintenanceService} from '../../../services/maintenance.service';

interface NavMenuItem {
    link: string;
    name: string;
    exact: boolean;
    enabled: boolean;
    icon?: string;
    materialIcon?: string;
    imgPath?: string;
    tooltipData?: any;
    className?: string;
}

@Component({
  selector: 'app-pro',
  templateUrl: './pro.component.html'
})
export class ProComponent implements OnInit, AfterViewInit {
    @Input() isAuthenticated = false;
    @Input() marketViewPage = false;
    @ViewChild('drawer') drawer;

    availableLangs: any[];
    suggestedLangs: any[];
    currentLang: any;

    items: NavMenuItem[] = [];
    itemsArray: NavMenuItem[] = [
        {
            'link': '/',
            'icon': 'bbi-candles',
            'name': 'USER.TRADING',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/wallets',
            'icon': 'bbi-wallet',
            'name': 'USER.WALLETS',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/trades',
            'icon': 'bbi-trades',
            'name': 'USER.TRADES',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/history',
            'icon': 'bbi-list',
            'name': 'USER.HISTORY',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/open-orders',
            'icon': 'bbi-open-list',
            'name': 'USER.OPEN_ORDERS',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/transactions',
            'icon': 'bbi-transfer',
            'name': 'USER.TRANSACTIONS',
            'exact': true,
            'enabled': true,
            'className': 'nav-icon-md'
        },
        {
            'link': '/affiliate',
            'icon': 'bbi-group',
            'name': 'USER.AFFILIATE',
            'exact': true,
            'enabled': true
        },
        {
            'link': '/position-history',
            'icon': 'bbi-list',
            'name': 'USER.POSITION_HISTORY',
            'exact': true,
            'enabled': environment.marginTrading.enabled
        },
        {
            'link': '/mt5',
            'icon': 'bbi-trades',
            'name': 'USER.MT5',
            'exact': true,
            'enabled': environment.mt5.enabled
        }
    ];

    supportTooltipData: any = {
        text: 'USER.SUPPORT',
        overlayClassName: 'nav-item-overlay right-aligned-overlay'
    };

    trackBarPos: number = 0;
    sidebarMode: 'push' | 'over' = 'push';
    sidebarOpened: boolean = false;
    backdropEnabled: boolean = false;
    closeAfterSelect: boolean = false;
    tabletSize: number = 700;
    isProfilePages: boolean = false;
    userRole: string;
    isMaintenance: boolean;

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.initSidebar(event.target.innerWidth);
    }

    constructor(public translate: TranslateService,
                public language: LanguageService,
                public router: Router,
                protected route: ActivatedRoute,
                private storage: StorageService,
                private menuService: MenuService,
                private maintenanceService: MaintenanceService) {
        this.isProfilePage();
    }

    ngOnInit() {
        this.userRole = this.storage.getItem('role');
        this.resolveRoutesList();
        this.initSidebar(document.documentElement.offsetWidth);
        this.getCurrentRouteIndex();
        this.availableLangs = this.language.getLanguages();
        this.getCurrentLanguage();
        this.getMaintenance();
    }

    ngAfterViewInit(): void {
        this.menuService.setMenuElement(this.drawer);
    }

    resolveRoutesList(): void {
        const neededItems = !this.isAuthenticated ? [] : this.itemsArray;
        for (const item of neededItems) {
            if (item.enabled) {
                item.tooltipData = {
                    text: item.name,
                    overlayClassName: 'nav-item-overlay right-aligned-overlay'
                };
                this.items.push(item);
            }
        }
    }

    getCurrentRouteIndex(): void {
        for (let i = 0; i < this.items.length; ++i) {
            if (this.router.url === this.items[i].link) {
                this.trackBarPos = i;
                break;
            }
        }
    }

    initSidebar(documentWidth: number): void {
        if (documentWidth <= this.tabletSize) {
            this.sidebarMode = 'over';
            this.sidebarOpened = false;
            this.backdropEnabled = true;
            this.closeAfterSelect = true;

        } else {
            this.sidebarMode = 'push';
            this.sidebarOpened = true;
            this.backdropEnabled = false;
            this.closeAfterSelect = false;
        }
    }

    getCurrentLanguage(): void {
        this.language.getLanguage()
            .subscribe((lang) => {
                this.currentLang = lang;
                this.updateSuggestedLangs();
            });
    }

    updateSuggestedLangs(): void {
        this.suggestedLangs = [];
        for (const lang of this.availableLangs) {
            if (this.currentLang.id !== lang.id) {
                this.suggestedLangs.push(lang);
            }
        }
    }

    onNavigate(index: number): void {
        this.trackBarPos = index;
        if (this.closeAfterSelect) {
            this.drawer.close();
        }
    }

    onLangSelect(lang: any): void {
        this.language.setLanguage(lang.id);
    }

    isProfilePage(): void {
        this.route.url.subscribe((data) => {
            if (data && data.length) {
                this.isProfilePages = data[0].path.includes('user');
            }
        });
    }

    getMaintenance(): void {
        this.maintenanceService.maintenanceData.subscribe(
            (result) => {
                this.isMaintenance = result.status === 'active';
            }
        );
    }
}
