<div *ngIf="chartIsReady">
    <canvas baseChart
            class="chart"
            [datasets]="chartData"
            [labels]="chartLabels"
            [options]="chartOptions"
            [colors]="chartColors"
            [chartType]="'pie'"
            [legend]="true">
    </canvas>
</div>
