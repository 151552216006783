import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bb-info-bar',
  templateUrl: './bb-info-bar.component.html'
})
export class BBInfoBarComponent implements OnInit {
    @Input() title: string;
    @Input() text: string;
    @Input() className: string;

    constructor() { }

    ngOnInit(): void {
    }

}
