<div fxFlex="100" fxLayout="row wrap">
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start"
         class="table font-normal position-history-table">
        <div fxFlex="100" fxLayout="row wrap" class="table-header" fxHide.lt-md>
            <div class="table-col" fxFlex="15" *ngIf="showUserInfo">{{'POSITION_HISTORY.USER' | translate}}</div>
            <div class="table-col" fxFlex="11" fxFlexOffset="1" fxFlexOffset.lt-md="0">{{'POSITION_HISTORY.TYPE' | translate}}</div>
            <div class="table-col" fxFlex="11" fxFlexOffset="1" fxFlexOffset.lt-md="0">{{'POSITION_HISTORY.DATE' | translate}}</div>
            <div class="table-col" fxFlex="11" fxFlexOffset="1" fxFlexOffset.lt-md="0">{{'POSITION_HISTORY.AMOUNT' | translate}}</div>
            <div class="table-col" fxFlex="11" fxFlexOffset="1" fxFlexOffset.lt-md="0">{{'POSITION_HISTORY.OPEN_PRICE' | translate}}</div>
            <div class="table-col" fxFlex="11" fxFlexOffset="1" fxFlexOffset.lt-md="0" *ngIf="showUserInfo">{{'POSITION_HISTORY.FEE' | translate}}</div>
            <div class="table-col" fxFlex="11" fxFlexOffset="1" fxFlexOffset.lt-md="0">{{'POSITION_HISTORY.SYMBOL' | translate}}</div>
            <div class="table-col" fxFlex="15" fxFlexOffset="1" fxFlexOffset.lt-md="0" *ngIf="!showUserInfo">{{'POSITION_HISTORY.LEVERAGE' | translate}}</div>
            <div class="table-col" fxFlex="11" fxFlexOffset="1" fxFlexOffset.lt-md="0" fxLayoutAlign="flex-end">{{"POSITION_HISTORY.ACTION" | translate}}</div>
        </div>

        <div fxFlex="100" fxLayout="column" class="table-body">
            <div class="table-row" fxLayout="row wrap" *ngFor="let position of items">

                <div class="table-col" fxFlex="15" fxFlex.lt-md="100" *ngIf="showUserInfo">
                    <b fxHide fxShow.lt-md="true">{{'POSITION_HISTORY.USER' | translate}}</b>
                    <span>{{position.email}}</span>
                </div>

                <div class="table-col" fxFlex="11" fxFlex.lt-md="100" fxFlexOffset="1" fxFlexOffset.lt-md="0">
                    <b fxHide fxShow.lt-md="true">{{'POSITION_HISTORY.TYPE' | translate}}</b>
                    <span [ngClass]="{'long': position.type === 'LONG', 'short': position.type === 'SHORT'}">{{position.type | uppercase}} (1:{{position.leverage}})</span>
                </div>

                <div class="table-col" fxFlex="11" fxFlex.lt-md="100" fxFlexOffset="1" fxFlexOffset.lt-md="0">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.DATE" | translate}}</b>
                    {{position.created | date: 'dd/MM/yyyy HH:mm:ss'}}
                </div>

                <div class="table-col" fxFlex="11" fxFlex.lt-md="100" fxFlexOffset="1" fxFlexOffset.lt-md="0">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.AMOUNT" | translate}}</b>
                    {{position.quantity | quantity: position.symbol }}
                </div>

                <div class="table-col" fxFlex="11" fxFlex.lt-md="100" fxFlexOffset="1" fxFlexOffset.lt-md="0">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.OPEN_PRICE" | translate}}</b>
                    {{position.price | price:  position.symbol}}
                </div>

                <div class="table-col" fxFlex="11" fxFlex.lt-md="100" fxFlexOffset="1" fxFlexOffset.lt-md="0" *ngIf="showUserInfo">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.FEE" | translate}}</b>
                    {{position.fee | quantity:  position.symbol}}
                </div>

                <div class="table-col" fxFlex="11" fxFlex.lt-md="100" fxFlexOffset="1" fxFlexOffset.lt-md="0">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.SYMBOL" | translate}}</b>
                    {{position.symbol}}
                </div>

                <div class="table-col" fxFlex="15" fxFlex.lt-md="100" fxFlexOffset="1" fxFlexOffset.lt-md="0" *ngIf="!showUserInfo">
                    <b fxHide fxShow.lt-md="true">{{'POSITION_HISTORY.LEVERAGE' | translate}}</b>
                    <span>{{position.leverage}}</span>
                </div>

                <div class="table-col" fxFlex="11" fxFlex.lt-md="100" fxFlexOffset="1" fxFlexOffset.lt-md="0">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.ACTION" | translate}}</b>
                    <b style="float: right">
                        <a (click)="closePosition(position)" class="cancel-order">
                            {{"COMMON.CLOSE" | translate}}</a>
                    </b>
                </div>
            </div>
        </div>
        <div fxFlex="100" fxLayoutAlign="center center" class="wallet orders"
             *ngIf="items && !items.length">
            <div fxFlex="0 1 auto">{{'TRANSACTIONS.NO_DATA_FOUND' | translate}}</div>
        </div>
    </div>

    <div fxFlex="100" class="pagination">
        <b class="current-page">{{'PAGE' | translate}}: {{page}}</b>
        <mat-paginator [length]="total"
                       [pageIndex]="page - 1"
                       [pageSize]="itemsPerPage"
                       [pageSizeOptions]="pageSizeOptions"
                       (page)="onPage($event)">
        </mat-paginator>
        <a class="current-page go-to-first-page" *ngIf="page > 1" (click)="toFirstPage()">
            {{'TO_FIRST_PAGE' | translate}}
        </a>
    </div>
</div>
