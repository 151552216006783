import {ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {BBTooltipComponent} from './bb-tooltip.component';
import {ComponentPortal} from '@angular/cdk/portal';
import {TooltipInterface} from './bb-tooltip.component';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[appBBTooltip]'
})
export class BBTooltipDirective implements OnInit, OnDestroy {
    @Input('appBBTooltip') tooltipData: TooltipInterface;

    private overlayRef: OverlayRef;

    constructor(private overlayPositionBuilder: OverlayPositionBuilder,
                private elementRef: ElementRef,
                private overlay: Overlay,
                public translate: TranslateService) {
    }

    ngOnInit(): void {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                originX: 'center',
                originY: 'center',
                overlayX: 'center',
                overlayY: 'center',
            }]);
        this.overlayRef = this.overlay.create({positionStrategy});
    }

    ngOnDestroy(): void {
        this.overlayRef.detach();
    }

    @HostListener('mouseenter')
    show() {

        // Create tooltip portal
        const tooltipPortal = new ComponentPortal(BBTooltipComponent);

        // Attach tooltip portal to overlay
        const tooltipRef: ComponentRef<BBTooltipComponent> = this.overlayRef.attach(tooltipPortal);

        let classNames: any;
        if (this.tooltipData.overlayClassName) {
            classNames = this.tooltipData.overlayClassName.split(' ');
        } else {
            classNames = ['top-aligned-overlay'];
        }

        for (const cn of classNames) {
            this.overlayRef.addPanelClass(cn);
        }

        tooltipRef.instance.tooltipData.className = this.tooltipData.className ? this.tooltipData.className : '';

        // Pass content to tooltip component instance

        if (this.tooltipData.text) {
            tooltipRef.instance.tooltipData.text = this.translate.instant(this.tooltipData.text);
        }

        if (this.tooltipData.textArray && this.tooltipData.textArray.length) {
            tooltipRef.instance.tooltipData.textArray = [];
            for (const t of this.tooltipData.textArray) {
                tooltipRef.instance.tooltipData.textArray.push(t);
            }
        }

        if (this.tooltipData.title) {
            tooltipRef.instance.tooltipData.title = this.translate.instant(this.tooltipData.title);
        }

        if (this.tooltipData.hasOwnProperty('show')) {
            tooltipRef.instance.tooltipData.show = this.translate.instant(this.tooltipData.show);
        }
    }

    @HostListener('mouseleave')
    hide() {
        this.overlayRef.detach();
    }

}
