<div class="pair-selector-container">
    <button mat-button mat-raised-button 
            (click)="opened = !opened; $event.stopPropagation();" 
            id="pair-selector-button"
            class="btn btn-basic pair-selector-button" 
            [ngClass]="{'opened': opened}">
                {{selectedSymbol.symbol}}
        <span class="arrow-wrapper">
            <span class="arrow"></span>
        </span>
    </button>
    <div class="box shadowed-bold pair-selector-toogle"
        (clickOutside)="clickOutsideSelector()"
        [ngClass]="{'opened': opened }">
        <div  class="responsive-table">

            <div class="box-header pair-selector-filters">
                <div class="pair-selector-search form-field form-secondary form-limited form-sm shadowed icon-inside form-search">
                    <input class="form-group"
                           name="filterValue"
                           type="text"
                           autocomplete="off"
                           (keyup)="updatePairPipe()"
                           [(ngModel)]="pairFilters.search">
                    <span class="icon">
                        <span class="icon-search bbi-search"></span>
                    </span>
                </div>

                <app-bb-radio-group
                    class="pair-selector-radio-group"
                    [options]="quteCurrency"
                    [classNames]="'radio-group-accent xs-distance'"
                    (onSelect)="onQuteChange($event)">
                </app-bb-radio-group>

                <app-bb-toogle-button
                    class="pair-selector-star"
                    [classNames]="'checked-accent'"
                    [enabledIcon] = "'bbi-star-filled'"
                    [disabledIcon] = "'bbi-star'"
                    [checked]="false"
                    (onChange)="filterFavorites($event)">
                </app-bb-toogle-button>
            </div>
            
            <div class="pairs-table grid-table">
                <div class="header row">
                    <div class="col pointer" (click)="sortPair()">
                        <span class="sort-arrows"
                              [ngClass]="pairFilters.sortSymbol"></span>
                        {{'MARKET_VIEW.PAIR' | translate}}
                    </div>
                    <div class="col">{{'MARKET_VIEW.PRICE' | translate}}</div>
                    <div class="col pointer" (click)="sortH24()">
                        <span class="sort-arrows"
                              [ngClass]="pairFilters.sortH24"></span>
                        {{'MARKET_VIEW.24H' | translate}}
                    </div>
                    <div class="col pointer" (click)="sortVol()">
                        <span class="sort-arrows"
                              [ngClass]="pairFilters.sortVol"></span>
                        {{'MARKET_VIEW.VOLUME' | translate}}
                    </div>
                    <div class="col"></div>
                </div>
                <div *ngIf="!(symbols | tickerFilter: pairFiltersUpdate : pairFilters).length" 
                    class="body row info-row" 
                    style="grid-template-columns: 100%">
                    <div>{{'TRANSACTIONS.NO_DATA_FOUND' | translate}}</div>
                </div>
                <div class="scrollable-container no-scroll" *ngIf="(symbols | tickerFilter: pairFiltersUpdate : pairFilters).length" >
                    <div class="scrollable">
                        <ng-scrollbar>
                            <div class="pairs-row body row"
                                (click)="selectPair(item)"
                                *ngFor="let item of symbols | tickerFilter: pairFiltersUpdate : pairFilters">
                                <div class="col">{{ item.symbol }}</div>
                                <div class="col">{{pairPrices[item.symbol] | price: item.symbol}}</div>
                                <div class="col" 
                                    [ngClass]="{'text-success': pairPriceChange[item.symbol] >= 0, 'text-warn': pairPriceChange[item.symbol] < 0}">
                                    {{pairPriceChange[item.symbol] | toFixed: 2}}%
                                </div>
                                <div class="col">{{pairVolume[item.symbol] | quantity: item.symbol}}</div>
                                <div class="col text-fx-end favorite-icon" (click)="addPairToFavorite(item.symbol); $event.stopPropagation();">
                                    <span class="pair-select-star bbi-star"
                                          *ngIf="!userFavoritePairs.includes(item.symbol)"></span>
                                    <span class="pair-select-star bbi-star-filled selected"
                                          *ngIf="userFavoritePairs.includes(item.symbol)"></span>
                                </div>
                            </div>
                        </ng-scrollbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
