import {AfterViewInit, Directive, ElementRef, OnInit} from '@angular/core';
import {MatInput} from '@angular/material/input';

@Directive({
    selector: '[appAutofocus]',
})
export class AutofocusDirective implements OnInit, AfterViewInit {

    constructor(private element: ElementRef) { }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.element.nativeElement.focus(), 1000);
    }

}
