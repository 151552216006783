import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {LoggerService} from '../../../services/logger.service';
import * as moment from 'moment';
import {AdminService} from '../../../services/admin.service';
import {Subscription} from 'rxjs';
import {ThemeService} from '../../../services/theme.service';
import {SocketStatusType, WsMainService} from '../../../services/wsMain.service';
import {Utl} from '../../../helpers/utl';
import {BBSnackbarComponent} from '../../bb-snackbar/bb-snackbar.component';

@Component({
    selector: 'app-main-layout',
    templateUrl: 'main-layout.template.html'
})
export class MainLayoutComponent implements OnInit, OnDestroy {
    role = 'user';
    isAuthenticated = true;
    email = '';
    firstName = '';
    lastName = '';
    verified = '';
    kycSubmitted = '';
    currentYear = 0;
    selectedLang = 'en';
    private getCurrentUserSubscription: Subscription;

    pendingPayoutsCount: number = 0;
    pendingKYCCount: number = 0;

    notificationPullingStartTimestamp: number;
    notificationsPuller: any;

    availableLangs: any = [
        {
            'slug': 'en',
            'name': 'English',
            'img': '../../../../../assets/img/flags/en.svg',
            'id': 'langEng'
        },
        {
            'slug': 'de',
            'name': 'Deutsch',
            'img': '../../../../../assets/img/flags/de.svg',
            'id': 'langDe'
        },
        {
            'slug': 'ru',
            'name': 'Русский',
            'img': '../../../../../assets/img/flags/ru.svg',
            'id': 'langRus'
        }
    ];

    themes: any = [
        {
            'color': '#f4f7d1',
            'name': 'light',
            'icon': 'wb_sunny'
        },
        {
            'color': '#314456',
            'name': 'dark',
            'icon': 'brightness_3'
        }
    ];
    currentTheme: any;
    canChangeTheme: boolean = true;

    langs: any = {};

    marketViewPage = false;

    private newUserTradeSubscription: Subscription = null;
    private newOrderChangedSubscription: Subscription = null;
    private wsStatusSubscription: Subscription = null;

    constructor(protected router: Router,
                protected route: ActivatedRoute,
                public translate: TranslateService,
                protected authService: AuthService,
                private userService: UserService,
                private adminService: AdminService,
                protected storage: StorageService,
                public snackBar: MatSnackBar,
                private logger: LoggerService,
                private storageService: StorageService,
                private wsMain: WsMainService,
                private themeService: ThemeService) {
        // Translator init
        const browserLang: string = translate.getBrowserLang();

        this.selectedLang = browserLang.match(/en|ru|de|es/) ? browserLang : 'en';

        if (['en', 'ru', 'de', 'es'].includes(localStorage.getItem('lang'))) {
            this.selectedLang = localStorage.getItem('lang');
        } else {
            this.selectedLang = 'en';
        }

        for (const lang of this.availableLangs) {
            this.langs[lang.slug] = lang;
        }

        this.role = this.storageService.getItem('role');
        this.verified = this.storage.getItem('verified');
        this.kycSubmitted = this.storage.getItem('kycSubmitted');
        this.translate.use(this.selectedLang);

        if (this.role !== 'user') {
            this.notificationPullingStartTimestamp = moment().subtract(7, 'day').valueOf();
        }
        this.route.url.subscribe(() => {
            const urlSegments = route.snapshot['_urlSegment'].segments;
            if (urlSegments && urlSegments.length && urlSegments[0].path === 'sessions') {
                this.themeService.setTheme({
                    newTheme: 'light',
                    oldTheme: 'dark'
                });
            }
        });
        this.router.events.subscribe(url => {
            if (url instanceof NavigationEnd) {
                this.marketViewPage = false;
                if (url) {
                    if (url.url.includes('/sessions/market-view')) {
                        this.marketViewPage = true;
                    }
                }
            }
        });
    }

    ngOnInit() {

        this.getCurrentYear();
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';

        if (!this.isAuthenticated) {

            if (!this.router.url.includes('sessions/') &&
                !this.router.url.includes('withdraw/') &&
                !this.router.url.includes('payment/') &&
                !this.router.url.includes('page/')) {
                this.router.navigate(['/sessions/signin']);
            }
            return;
        }

        const userName = this.storage.getItem('firstName');
        userName ? this.firstName = userName : this.firstName = '';


        this.email = this.storage.getItem('email');

        this.lastName = this.storage.getItem('lastName');

        if (['en', 'ru', 'de', 'es'].includes(localStorage.getItem('lang'))) {
            this.selectedLang = localStorage.getItem('lang');
        } else {
            this.selectedLang = 'en';
        }
        this.translate.use(this.selectedLang);

        if (['admin', 'support-admin', 'admin-kyc'].includes(this.role)) {
            this.notificationsPuller = setInterval(() => {
                if (this.storage.getItem('TFAEnabled') === 'true' && this.storage.getItem('TFAVerified') === 'false') {
                    return;
                }
                this.adminService.getPendingKYCCount(this.notificationPullingStartTimestamp)
                    .subscribe(
                        (kycCountResult) => {
                            const kycCount = kycCountResult.result;
                            if (this.pendingKYCCount < kycCount) {
                                this.snackBar.openFromComponent(
                                    BBSnackbarComponent, {
                                        data: {
                                            type: 'INFO',
                                            text: this.translate.instant('USER.YOU_GOT_NEW_NOTIFICATION')
                                        },
                                        duration: 10000
                                    });
                                this.playNotificationAudio('new_kyc');
                            }

                            this.pendingKYCCount = kycCount;
                        },
                        (err) => {
                            this.logger.error(err);
                        });
            }, 12000);
        }

        if (['admin', 'support-admin', 'accountant'].includes(this.role)) {
            this.notificationsPuller = setInterval(() => {
                if (this.storage.getItem('TFAEnabled') === 'true' && this.storage.getItem('TFAVerified') === 'false') {
                    return;
                }
                this.adminService.getPendingPayoutsCount(this.notificationPullingStartTimestamp)
                    .subscribe(
                        (payoutCountResult) => {
                            const payoutsCount = payoutCountResult.result;
                            this.pendingPayoutsCount = payoutsCount;

                            if (this.pendingPayoutsCount < payoutsCount) {
                                this.snackBar.openFromComponent(
                                    BBSnackbarComponent, {
                                        data: {
                                            type: 'INFO',
                                            text: this.translate.instant('USER.YOU_GOT_NEW_NOTIFICATION')
                                        },
                                        duration: 10000
                                    });
                                this.playNotificationAudio('new_payout');
                            }
                        },
                        (err) => {
                            this.logger.error(err);
                        }
                    );
            }, 12000);
        }

        this.wsStatusSubscription = this.wsMain.getSocketStatus().subscribe((status) => {
            if (status === SocketStatusType.CONNECTED) {
                if (this.newUserTradeSubscription) {
                    this.newUserTradeSubscription.unsubscribe();
                    this.newUserTradeSubscription = null;
                }

                if (this.newOrderChangedSubscription) {
                    this.newOrderChangedSubscription.unsubscribe();
                    this.newOrderChangedSubscription = null;
                }
                if (this.isAuthenticated) {
                    this.wsMain.authorizeSocket();
                }
            } else if (status === SocketStatusType.AUTHORIZED) {
                this.newUserTradeSubscription = this.wsMain.subscribeToEvent(['newUserTrade']).subscribe((msg) => {
                    this.onNewUserTrade(msg);
                });
                this.newOrderChangedSubscription = this.wsMain.subscribeToEvent(['orderUpdate']).subscribe((msg) => {
                    this.onNewOrderUpdate(msg);
                });
            }
        });
    }

    onNewUserTrade(msg): void {
        const userTrade = msg.result;

        const dataToShow = {
            type: 'INFO',
            title: this.translate.instant('NEW_TRADE'),
            amount: userTrade.quantity,
            price: userTrade.price,
            symbol: userTrade.symbol
        };

        this.snackBar.openFromComponent(
            BBSnackbarComponent, {
                data: dataToShow
            });
    }

    onNewOrderUpdate(msg): void {
        const updatedOrder = msg.result;
        let title = null;
        let price = null;
        let quantity = null;
        let timeout = 0;
        switch (updatedOrder.status) {
            case 'cancelled':
                title = this.translate.instant('ORDER_CANCELED');
                quantity = Utl.formatQuantity(updatedOrder.symbol, updatedOrder.quantity);
                price = Utl.formatPrice(updatedOrder.symbol, updatedOrder.price);
                break;
            case 'new':
                if (updatedOrder.cumQuantity > 0) {
                    return;
                }
                title = this.translate.instant('ORDER_CREATED');
                quantity = Utl.formatQuantity(updatedOrder.symbol, updatedOrder.quantity);
                price = Utl.formatPrice(updatedOrder.symbol, updatedOrder.price);
                break;
            case 'filled':
                title = this.translate.instant('FILLED');
                quantity = Utl.formatQuantity(updatedOrder.symbol, updatedOrder.cumQuantity);
                price = Utl.formatPrice(updatedOrder.symbol, updatedOrder.price);
                if (Number(updatedOrder.averagePrice)) {
                    price = Utl.formatPrice(updatedOrder.symbol, updatedOrder.averagePrice);
                }
                timeout = 2000;
                break;
        }

        const dataToShow = {
            type: 'INFO',
            title: title,
            amount: quantity,
            price: price,
            symbol: updatedOrder.symbol
        };

        setTimeout(() => {
            this.snackBar.openFromComponent(
                BBSnackbarComponent, {
                    data: dataToShow
                });
        }, timeout);

    }

    playNotificationAudio(type: 'new_kyc' | 'new_payout') {
        const audio = new Audio();
        audio.src = `../../../../assets/audio/${type}.mp3`;
        audio.load();
        audio.pause();
        audio.play();
    }

    ngOnDestroy() {
        if (this.getCurrentUserSubscription) {
            this.getCurrentUserSubscription.unsubscribe();
        }
        clearInterval(this.notificationsPuller);

        if (this.wsStatusSubscription) {
            this.wsStatusSubscription.unsubscribe();
            this.wsStatusSubscription = null;
        }

        if (this.newUserTradeSubscription) {
            this.newUserTradeSubscription.unsubscribe();
            this.newUserTradeSubscription = null;
        }

        if (this.newOrderChangedSubscription) {
            this.newOrderChangedSubscription.unsubscribe();
            this.newOrderChangedSubscription = null;
        }
    }

    getCurrentYear() {
        this.currentYear = (new Date()).getFullYear();
    }

}
