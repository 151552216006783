import {Injectable} from '@angular/core';

@Injectable()
export class ValidateHelper {

    public patterns: any = {
        /*email before @ part should contain at least two symbols(letters or digits)
        * and can contain "." and "-", should contain @, and after @ part should contain
        * at least one letter, shoult contain ".", and after "." part should contain 2 or 3 letters*/
        email: /^\w{2,}([.-]?\w+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
        password: /^[\x00-\xFF]{6,}$/
    };

    constructor() {
    }

    email(email: string) {
        return (email && !!email.match(this.patterns.email));
    }

    password(password: string) {
        return (password && !!password.match(this.patterns.password));
    }
}
