<div class="download-apps-container"
     [ngClass]="{'show': canSuggestMobile}">
    <div class="download-apps-panel">
        <div class="title-box">
            <p class="title">{{'COMMON.GET_MOBILE_APP' | translate}}</p>
        </div>
        <div class="text-box">
            <p class="text">{{'COMMON.TRADE_WITH_MOBILE_APP' | translate}}</p>
        </div>
        <div class="mobile-app-links-box">
            <a class="button-app-link"
               [href]="appStoreUrl" target="_blank"
               [ngClass]="{'show': showAppStoreLink}">
                <span class="button-icon-box">
                    <img class="button-icon" src="assets/img/app_store.svg" alt="">
                </span>
                <span class="button-text-box">
                    <span class="button-text" [innerHTML]="'COMMON.APP_STORE_LINK' | translate"></span>
                </span>
            </a>
            <a class="button-app-link"
               [href]="googlePlayUrl" target="_blank"
               [ngClass]="{'show': showGooglePlayLink}">
                <span class="button-icon-box">
                    <img class="button-icon" src="assets/img/google_play.svg" alt="">
                </span>
                <span class="button-text-box">
                    <span class="button-text" [innerHTML]="'COMMON.GOOGLE_PLAY_LINK' | translate"></span>
                </span>
            </a>
        </div>
        <div class="download-apps-close-box"
             (click)="removeSuggestion()">
            <p class="close-text">{{'COMMON.NO_THANKS' | translate}}</p>
        </div>
    </div>
</div>
