import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'tickerFilter'})
export class TickerFilterPipe implements PipeTransform {

    transform(items: any[], update, filter): any {
        if (!items || !filter) {
            return items;
        }

        let copyItems = [...items];


        if (filter.activeFav) {
            copyItems = copyItems.filter(item => {
                if (filter.favorites.includes(item.symbol)) {
                    return true;
                }
            });
        }

        if (filter.sortVol) {
            if (filter.sortVol === 'max-min') {
                copyItems.sort((a, b) => {
                    if (Number(a.quoteVolume) < Number(b.quoteVolume)) { return 1; }
                    if (Number(a.quoteVolume) > Number(b.quoteVolume)) { return -1; }
                    return 0;
                });
            } else {
                copyItems.sort((a, b) => {
                    if (Number(a.quoteVolume) < Number(b.quoteVolume)) { return -1; }
                    if (Number(a.quoteVolume) > Number(b.quoteVolume)) { return 1; }
                    return 0;
                });
            }
        }

        if (filter.sortSymbol) {
            copyItems.sort((a, b) => {
                if (filter.sortSymbol === 'a-z') {
                    if (a.symbol[0].toLowerCase() < b.symbol[0].toLowerCase()) { return -1; }
                    if (a.symbol[0].toLowerCase() > b.symbol[0].toLowerCase()) { return 1; }
                    return 0;
                } else {
                    if (a.symbol[0].toLowerCase() < b.symbol[0].toLowerCase()) { return 1; }
                    if (a.symbol[0].toLowerCase() > b.symbol[0].toLowerCase()) { return -1; }
                    return 0;
                }
            });
        }

        if (filter.sortH24) {
            if (filter.sortH24 === 'max-min') {
                copyItems.sort((a, b) => {
                    if (Number(a.priceChange) < Number(b.priceChange)) { return 1; }
                    if (Number(a.priceChange) > Number(b.priceChange)) { return -1; }
                    return 0;
                });
            } else {
                copyItems.sort((a, b) => {
                    if (Number(a.priceChange) < Number(b.priceChange)) { return -1; }
                    if (Number(a.priceChange) > Number(b.priceChange)) { return 1; }
                    return 0;
                });
            }
        }


        if (!filter.sortSymbol)  {
            const copyItemsPrivilege = copyItems.filter(item => {
                return (item.base.toUpperCase().indexOf(filter.search.toUpperCase()) !== -1);
            });

            const copyItemsNotPrivilege = copyItems.filter(item => {
                return (item.base.toUpperCase().indexOf(filter.search.toUpperCase()) === -1);
            });

            copyItems = [...copyItemsPrivilege, ...copyItemsNotPrivilege];
        }


        return copyItems.filter(item => {
            if (filter.quote !== 'ANY') {
                return (item.symbol.toUpperCase().indexOf(filter.search.toUpperCase()) !== -1 && item.quote === filter.quote);
            } else {
                return item.symbol.toUpperCase().indexOf(filter.search.toUpperCase()) !== -1;
            }
        });
    }

}
