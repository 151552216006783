import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/user.service';
import {CurrencyService} from '../../services/currency.service';
import {BBSnackbarComponent} from '../bb-snackbar/bb-snackbar.component';

@Component({
    selector: 'app-redeem-cgift-component',
    templateUrl: 'redeem-cgift.template.html'
})
export class RedeemCgiftComponent implements OnInit {
    cardNumber: string;
    pinCode: string;
    currencies: any = [];
    selectedCurrency: string = 'BTC';

    constructor(public dialogRef: MatDialogRef<RedeemCgiftComponent>,
                public translate: TranslateService,
                private snackBar: MatSnackBar,
                private userService: UserService,
                private currencyService: CurrencyService) {

    }

    ngOnInit() {
        this.currencyService.getCurrencies()
            .subscribe((result) => {
                this.currencies = result;
            }, (err) => {
                this.snackBar.openFromComponent(BBSnackbarComponent, {
                    data: {type: 'ERROR', text: err}
                });
            });
    }

    redeem() {
        this.userService.redeemGiftCard(this.selectedCurrency, this.cardNumber, this.pinCode)
            .subscribe((result) => {
                this.dialogRef.close({result: true});
            }, (err) => {
                this.snackBar.openFromComponent(BBSnackbarComponent, {
                    data: {
                        type: 'ERROR',
                        text: this.translate.instant('WALLETS.REDEEM_INVALID')
                    }
                });
            });
    }

    close() {
        this.dialogRef.close();
    }
}

