import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

export interface CardOptionInterface {
    label: string;
    value: any;
    icon?: string;
    iconUrl?: string;
    text?: string;
}

@Component({
  selector: 'app-bb-card-group',
  templateUrl: './bb-card-group.component.html'
})
export class BBCardGroupComponent implements OnInit {
    private _optionsSubject = new BehaviorSubject<CardOptionInterface[]>([]);
    private _selectedSubject = new BehaviorSubject<any>(null);

    @Input() classNames: string;
    @Input() checkIcon: string;
    @Input() set options(data: CardOptionInterface[]) {
        this._optionsSubject.next(data);
    }
    @Input() set selected(data: any) {
        this._selectedSubject.next(data);
    }
    @Output() onSelect: EventEmitter <any> = new EventEmitter();

    get options(): CardOptionInterface[] {
        return this._optionsSubject.getValue();
    }

    get selected(): any {
        return this._selectedSubject.getValue();
    }

    _options: CardOptionInterface[] = [];
    _selected: CardOptionInterface;

    constructor(public translate: TranslateService) {
    }

    ngOnInit(): void {
        this._optionsSubject.subscribe((data) => {
            this._options = data && data.length ? [...data] : [];
        });

        this._selectedSubject.subscribe((data) => {
            this._selected = data;
            if (!this._selected) {
                this._selected = this._options[0].value;
            }
        });

    }

    onSelection(option: any): void {
        this.onSelect.emit(option.value);
    }
}
