<div class="slide-toggle-wrapper"
     [ngClass]="classNames">
    <label *ngIf="label" class="form-group-label">{{label | translate}}</label>
    <mat-slide-toggle
        [checked]="_checked"
        [disabled]="disabled"
        (change)="onSlideToggle($event)">
        <span class="icon-false"
              [innerHtml]="_togglers[0].icon">
        </span>
        <span class="icon-true"
              [innerHtml]="_togglers[1].icon">
        </span>
    </mat-slide-toggle>
    <span *ngIf="_toggledObj.name" class="toggle-name">{{_toggledObj.name | translate}}</span>
</div>
