<div class="bb-mat-dialog-container">
    <div class="bb-mat-dialog-header">
        <div class="bb-mat-dialog-title">{{'WALLETS.REDEEM_COUPON' | translate}}</div>
        <div class="bb-mat-dialog-close">
            <span class="material-icons" (click)="close()">close</span>
        </div>
    </div>

    <div class="bb-mat-dialog-body">
        <div class="form-field form-basic form-xs shadowed form-box-margin">
            <label for="card_number" class="form-group-label">{{'WALLETS.CARD_NUMBER' | translate}}</label>
            <input id="card_number"
                   class="form-group"
                   name="card_number"
                   type="text"
                   [(ngModel)]="cardNumber">
        </div>


        <div class="form-field form-basic form-xs shadowed form-box-margin">
            <label for="pin" class="form-group-label">{{'WALLETS.PIN' | translate}}</label>
            <input id="pin"
                   class="form-group"
                   name="pin"
                   type="text"
                   [(ngModel)]="pinCode">
        </div>

        <div class="align-all-fxStr-ctr bb-coupon">
            <a href="https://bitblinx.com/bitblinx-coupon/" target="_blank">{{'WALLETS.BUY_COUPON' | translate}}</a>
        </div>
    </div>

    <div class="bb-mat-dialog-footer">
        <button mat-button mat-raised-button class="btn btn-accent" (click)="redeem()">
            {{'WALLETS.REDEEM' | translate}}
        </button>
    </div>

</div>
