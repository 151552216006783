import { Directive, HostListener, ElementRef, OnInit, Input } from '@angular/core';
import {AmountPipe} from '../pipes/common/amount.pipe';
import {CurrencyService} from '../services/currency.service';

@Directive({ selector: '[quantityDirective]' })
export class QuantityFormatterDirective implements OnInit {
    private el: HTMLInputElement;
    @Input() symbol: string;
    quantity = 0;

    constructor(
        private elementRef: ElementRef,
        private amountPipe: AmountPipe
    ) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
        if (this.el.value !== '') {
            this.el.value = this.amountPipe.transform(this.el.value, this.symbol);
        }

        const currencies = CurrencyService.getSymbolsFromCache();
        if (!currencies || !currencies[this.symbol]) {
            return null;
        }

        this.quantity = currencies[this.symbol].amountPrecision;
    }

    @HostListener('focus', ['$event.target.value'])
    onFocus(value) {
        this.checkNumber(value);
    }

    @HostListener('input', ['$event.target.value'])
    onChange(value) {
        this.checkNumber(value);
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value) {
        this.checkNumber(value);
    }

    checkNumber(value) {
        if (this.el.value !== '') {
            const dots = value.split('.');

            if (dots.length === 2) {
                if (dots[1].length > this.quantity) {
                    this.el.value = dots[0] + '.' + dots[1].slice(0, this.quantity);
                    return;
                }
            }

            if (dots.length > 2) {
                this.el.value = dots[0] + '.' + dots[1].slice(0, this.quantity);
            }
        }
    }
}
