import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TooltipInterface} from '../directives/bb-tooltip/bb-tooltip.component';
import {Router} from '@angular/router';
export interface StepperInterface {
    value: any;
    label: string;
    link: string;
    isDone?: boolean;
    current?: boolean;
    tooltipData?: TooltipInterface;
}

@Component({
    selector: 'app-bb-stepper',
    templateUrl: './bb-stepper.template.html'
})
export class BBSTepperComponent implements OnInit, OnChanges {
    _steps: StepperInterface[] = [];
    _doneStep: number;
    _buttonsRippleColor: string = '#00000020';

    @Input() set steps(data: StepperInterface[]) {
        if (data) {
            this._steps = data;
        }
    }

    @Input() set done(value: number) {
        if (value >= 0) {
            this._doneStep = value;
        }
    }

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        if (this._doneStep >= 0) {
            this.assignSteps();
        } else {
            this._steps[0].current = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('done') && !changes.done.isFirstChange()) {
            if (changes.done.currentValue) {
                this._doneStep = changes.done.currentValue;
                this.assignSteps();
            }
        }
    }

    assignSteps(): void {
        for (const step of this._steps) {
            if (step.value <= this._doneStep) {
                step.isDone = true;
                step.current = false;
            } else if (step.value === this._doneStep + 1) {
                step.isDone = false;
                step.current = true;
            } else {
                step.isDone = false;
                step.current = false;
            }
        }
    }

}
