import {Pipe, PipeTransform} from '@angular/core';
import {Utl} from '../../helpers/utl';

@Pipe({name: 'price'})
export class PricePipe implements PipeTransform {
    
    transform(value: any, symbol: string) {
        return Utl.formatPrice(symbol, value);
    }
}
