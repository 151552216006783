<!-- pro layout -->
<app-pro *ngIf="isAuthenticated || marketViewPage"
    [isAuthenticated]="isAuthenticated" 
    [marketViewPage]="marketViewPage">
</app-pro>
<!-- pro layout -->

<!-- sessions layout -->
<app-session *ngIf="!isAuthenticated && !marketViewPage"></app-session>
<!-- sessions layout -->

<!-- mobile app suggester -->
<app-bb-suggester></app-bb-suggester>
<!-- mobile app suggester -->

