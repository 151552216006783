import {Pipe, PipeTransform} from '@angular/core';
import {Utl} from '../../helpers/utl';

@Pipe({name: 'total'})
export class TotalPipe implements PipeTransform {


    transform(value: any, symbol: string) {
        return Utl.formatTotal(symbol, value);
    }
}
