import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {WebsocketService} from '../websocket/websocket.service';
import {StorageService} from './storage.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

export enum SocketStatusType {
    DISCONNECTED,
    CONNECTED,
    AUTHORIZED
}

@Injectable()
export class WsMainService {
    
    env = environment;
    selectedSymbol = null;
    subscriptions: any = {};
    socketStatusSubject: BehaviorSubject<SocketStatusType> = null;
    

    // Our constructor calls our wsService connect method
    constructor(private wsService: WebsocketService, public storage: StorageService) {
        this.socketStatusSubject = new BehaviorSubject<any>(SocketStatusType.DISCONNECTED);

        if(this.wsService.isConnected) {
            this.onSocketConnect();
        }

        this.wsService.status.subscribe((result) => {     
            if(result) {
                this.onSocketConnect();
            } else {
                this.socketStatusSubject.next(SocketStatusType.DISCONNECTED);    
            }
        });                    
    }

    onSocketConnect() {
        const keys = Object.keys(this.subscriptions);
        for (const key of keys) {
            const item = this.subscriptions[key];
            this.initSubscriptions(item.method, item.params);
        }
        this.socketStatusSubject.next(SocketStatusType.CONNECTED);            
        // this.authorizeSocket();
    }

    getSocketStatus(): BehaviorSubject<SocketStatusType> {
        return this.socketStatusSubject;
    }

    authorizeSocket() {
        let authObserver = this._authorizeSocket();
        if(authObserver) {
            let authSubscription = authObserver.subscribe((res) => {
                authSubscription.unsubscribe();
                this.socketStatusSubject.next(SocketStatusType.AUTHORIZED);
            });
        }
    }

    _authorizeSocket(): Observable<any>  {        
        const customToken = this.storage.getItem('token');
        if (!customToken) {
            return null;
        }
        this.wsService.send('authorize', {token: customToken});
        return this.wsService.on<any>(['authorize']);
    }

    subscribeToEvent(events): Observable<any> {
        return this.wsService.on<any>(events);        
    }

    executeCommand(method, params): Observable<any> {
        this.wsService.send(method.subscribe, params);
        return this.wsService.on<any>(method.events);
    }

    initSubscriptions(method: any, params: any): Observable<any>  {
        if (this.wsService.isConnected) {
            this.unsubscribe(method);
            this.wsService.send(method.subscribe, params);

        }
        this.subscriptions[method.subscribe] = {method: method, params: params};
        this.selectedSymbol = params.symbol;

        return this.wsService.on<any>(method.events);
    }

    unsubscribe(method) {
        if (this.subscriptions && this.subscriptions.hasOwnProperty(method.subscribe)) {
            this.wsService.send(method.unsubscribe, {'symbol': this.subscriptions[method.subscribe].params.symbol});
        }
    }

    destroySubscriptions() {
        const keys = Object.keys(this.subscriptions);
        for (const key of keys) {
            const item = this.subscriptions[key];
            this.unsubscribe(item.method);
        }

        this.selectedSymbol = null;
    }
}
