import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html'
})
export class SessionComponent implements OnInit {
    isErrorPage: boolean = false;

    constructor(private route: ActivatedRoute) {
        route.url.subscribe(() => {
            this.isErrorPage = route.snapshot['_urlSegment'].segments[1].path === '404';
        });
    }

    ngOnInit(): void {
    }

}
