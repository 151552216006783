import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {BBSnackbarComponent} from '../../components/bb-snackbar/bb-snackbar.component';

@Component({
    selector: 'app-withdraw-activate',
    templateUrl: 'withdraw-activate.component.html'
})
export class WithdrawActivateComponent implements OnInit {
    token = '';
    type;

    constructor(private translate: TranslateService,
                private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private authService: AuthService,
                private userService: UserService,
                private router: Router) {

    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.token = params['token'];
            this.submitData();
        });
    }

    submitData() {
        this.userService.confirmWithdraw(this.token)
            .subscribe(
                (result) => {
                    this.type = result.result.status;
                },
                (err) => {
                    this.snackBar.openFromComponent(BBSnackbarComponent, {
                        data: {type: 'ERROR', text: err}
                    });
                    this.router.navigate(['/']);
                });
    }
}
