import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import 'rxjs/add/operator/do';
import {LoggerService} from './logger.service';
import {StorageService} from './storage.service';
import {AuthService} from './auth.service';
import {TranslateService} from '@ngx-translate/core';
import {Utl} from '../helpers/utl';

class FeError {
    public message: string;
    public type: string;
    public data: any;

    constructor(message: string, type: string, data?: any) {
        this.message = message;
        this.data = data;
        this.type = type;
    }

    public toString = (): string => {
        return this.message;
    }
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private storage: StorageService,
                private logger: LoggerService,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private translate: TranslateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('/api/') === -1) {
            return next.handle(req);
        }

        let lang = localStorage.getItem('lang');
        if (Utl.isNullOrUndefined(lang)) {
            lang = 'en';
        }
        req = req.clone({
            setHeaders: {
                // 'Content-Type': 'application/json',
                'Accept-Language': lang
            }
        });

        if (!req.headers.has('Authorization')) {
            const token = this.storage.getItem('token');
            if (token) {
                req = req.clone({
                    setHeaders: {
                        'Authorization': `${environment.authHeaderPrefix} <${token}>`
                    }
                });
            }
        }

        const started = Date.now();
        return next
            .handle(req)
            .do(event => {
                if (event instanceof HttpResponse) {
                    const elapsed = Date.now() - started;
                    this.logger.debug(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
                    if (!event.body.status) {
                        if (event.body.code === 401) {
                            this.authService.unauthorizedHandler();
                        }

                        if (event.body.code === 503) {
                            this.authService.clearStorage();
                            this.router.navigate(['/sessions/maintenance']);
                        }

                        if (event.body.code === 801) {
                            this.router.navigate(['/user/tfa']);
                        }

                        const messageText = this.constructErrorMessage(event.body);
                        if (Utl.isNullOrUndefined(event.body.data)) {
                            this.logger.error(`Error: ${req.urlWithParams}`);
                            if (event.body.code === 559) { // NEED_VERIFICATION_VIA_EMAIL
                                throw new FeError(messageText, event.body.type);
                            }

                            throw new Error(messageText);
                        } else {
                            this.logger.error(`Error: ${req.urlWithParams}`);
                            throw new FeError(messageText, event.body.type, event.body.data);
                        }
                    } else {
                        event = event.clone({body: event.body.result});
                        return event;
                    }
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    throw new Error('Network Error');
                }
            });
    }

    private constructErrorMessage(responseBody): string {
        const translatedMessage = this.translate.instant(`${environment.beErrorLangKey}.${responseBody.type}`);
        if (translatedMessage !== responseBody.type) {
            return translatedMessage;
        }
        return responseBody.message;
    }
}
