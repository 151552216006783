import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';
import {map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Utl} from '../helpers/utl';

@Injectable()
export class AuthService implements CanActivate {
    private readonly hostUrl: string;
    private isAuthenticated = true; // Set this value dynamically
    private redirectUrl: string = '/';

    constructor(private router: Router, private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const role = route.data['role'] as string;

        if (this.isAuthenticated) {
            // if (this.storage.getItem('role') !== role) {
            //     return false;
            // }
            return true;
        }

        if (state.url !== '/' && !state.url.includes('session/') && !state.url.includes('/admin')) {
            this.redirectUrl = state.url;
        }
        const lang: string = localStorage.getItem('lang');
        if (Utl.isNullOrUndefined(lang)) {
            this.router.navigate(['/sessions/market-view']);
        } else {
            this.router.navigate([`/${lang}/sessions/market-view`]);
        }
        return false;
    }

    register(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/register`, data, '');
    }

    login(email: string, password: string, captcha: string): Observable<any> {
        return this.networkHelper.post<any>(`${this.hostUrl}/auth/login`,
            {captcha: captcha},
            `${email}:${password}`)
            .pipe(map((result) => {
                    this.isAuthenticated = true;
                    if (['admin', 'support-admin', 'accountant'].includes(result.result.role)) {
                        this.router.navigate(['/admin']);
                        this.redirectUrl = '/admin';
                    } else if (result.result.role === 'admin-kyc') {
                        this.router.navigate(['/admin-kyc']);
                        this.redirectUrl = '/admin-kyc';
                    } else {
                        const url = this.redirectUrl;
                        const urlParts = url.split('?');

                        let params = {};
                        if (urlParts.length > 1) {
                            params = Utl.getParams(urlParts[1]);
                        }

                        this.router.navigate([urlParts[0]] , { queryParams: params });
                        this.redirectUrl = '/';
                    }
                    return result.result;
                },
                (error) => {
                    return throwError(error);
                }));
    }

    activate(token): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/auth/activate/${token}`, {}, '');
    }

    logout(): Observable<any> {
        this.redirectUrl = '/';
        return this.networkHelper.post(`${this.hostUrl}/auth/logout`, {}).pipe(map((result) => {
            this.clearStorage();
        }));
    }

    getSession(token): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/auth/activate/${token}`, {}, '');
    }

    public clearStorage() {
        this.storage.removeItem('token');
        this.storage.removeItem('userId');
        this.storage.removeItem('role');
        this.storage.removeItem('firstName');
        this.storage.removeItem('lastName');
        this.storage.removeItem('email');
        this.storage.removeItem('avatarDirName');
        this.storage.removeItem('avatarFileName');
        this.storage.removeItem('verified');
        this.storage.removeItem('isLoggedin');
        this.storage.removeItem('kycSubmitted');
        this.storage.removeItem('TFAEnabled');
        this.storage.removeItem('TFAVerified');
        this.storage.removeItem('kycSubmitted');
        this.storage.removeItem('favoritePairs');
    }

    forgotStep1(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/forgot`, data, '');
    }

    forgotStep2(token, password): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/auth/forgot-confirm`, {token: token, password: password}, '');
    }

    resendActivationEmail(email): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/resend`, {email: email});
    }

    changePassword(oldPassword, newPassword): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/auth/change-password`, {
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    }

    public unauthorizedHandler(milliseconds: number = 100): void {
        this.clearStorage();

        setTimeout(() => {
            window.location.reload();
        }, milliseconds);
    }
}
