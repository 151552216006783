import {Injectable} from '@angular/core';
import * as moment from 'moment';
import * as json2csv from 'json2csv';
import {CurrencyService} from '../services/currency.service';


@Injectable()
export class Utl {

    private static _emailRegex: any = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    // password should contain at least one digit, one lower and
    // one upper case letters, should contain at least 6 symbols
    private static _passwordRegex: any = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/0-9a-zA-Z]{6,}$/;

    static get emailPattern(): any {
        return this._emailRegex;
    }

    static get passwordPattern(): any {
        return this._passwordRegex;
    }

    static isValidEmail(email: string): any {
        return email && email.match(this._emailRegex);
    }

    static isValidPassword(password: string): any {
        return password && password.match(this._passwordRegex);
    }

    static isValidNumber(data) {
        return (Utl.isSet(data) && !isNaN(parseFloat(data)));
    }

    static isSet(data) {
        return data !== undefined && data !== null;
    }

    static startOfDayWithTimezone(date): number {
        return (moment(date).startOf('day').toDate().getTime());
    }

    static endOfDayWithTimezone(date): number {
        return moment(date).endOf('day').toDate().getTime();
    }

    static exportHandler(result: any, fileName: string) {
        const blob = new Blob([result]);
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            // IE: "Access is denied";
            // see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            a.click();
            document.body.removeChild(a);
        }
    }

    static exportToCsv(data: any, fileName: string) {
        const res = json2csv.parse(data);
        Utl.exportHandler(res, fileName);
    }

    static formatFixed(value, precisison) {
        if (typeof value === 'string') {
            value = parseFloat(value);
        }
        if (typeof value !== 'number') {
            return null;
        }

        return value.toFixed(precisison);
    }

    static formatPrice(symbol, price) {
        if (typeof price === 'string') {
            price = parseFloat(price);
        }

        if (typeof price !== 'number') {
            return null;
        }

        const symbols = CurrencyService.getSymbolsFromCache();
        if (!symbols || !symbols[symbol]) {
            return price.toFixed(8);
        }

        return price.toFixed(symbols[symbol].pricePrecision);
    }

    static formatTotal(symbol, price) {
        if (typeof price === 'string') {
            price = parseFloat(price);
        }
        if (typeof price !== 'number') {
            return null;
        }

        const symbols = CurrencyService.getSymbolsFromCache();
        if (!symbols || !symbols[symbol]) {
            return price.toFixed(6);
        }

        return price.toFixed(symbols[symbol].totalPrecision);
    }

    static formatQuantity(symbol, price) {
        if (typeof price === 'string') {
            price = parseFloat(price);
        }
        if (typeof price !== 'number') {
            return null;
        }

        const symbols = CurrencyService.getSymbolsFromCache();
        if (!symbols || !symbols[symbol]) {
            return price.toFixed(6);
        }

        return price.toFixed(symbols[symbol].amountPrecision);
    }

    static formatAmount(currency, quantity) {
        const currencyObj = CurrencyService.getFromCache(currency);
        if (!currencyObj) {
            return null;
        }
        if (typeof quantity === 'string') {
            quantity = parseFloat(quantity);
        }
        if (typeof quantity !== 'number') {
            return null;
        }

        return quantity.toFixed(currencyObj.amountPrecision);
    }

    static getParams (url) {
        const params = {};
        const vars = url.split('&');

        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }

        return params;
    }

    static removeItemsFromArray(arr: any[], removed: any[]): any[] {
        let what;
        let length = removed.length;
        let deletedItem;

        while (length && arr.length) {
            what = removed[--length];
            while ((deletedItem = arr.indexOf(what)) !== -1) {
                arr.splice(deletedItem, 1);
            }
        }
        return arr;
    }

    static isUserInfoFilled(user: any): boolean {
        return !this.isEmptyField(user.firstName) &&
            !this.isEmptyField(user.lastName) &&
            !this.isEmptyField(user.DOB) &&
            !this.isEmptyField(user.gender) &&
            !this.isEmptyField(user.address1) &&
            !this.isEmptyField(user.postalCode) &&
            !this.isEmptyField(user.nationality) &&
            !this.isEmptyField(user.country) &&
            !this.isEmptyField(user.city) &&
            !this.isEmptyField(user.phone);
    }

    static isEmptyField(value: any): boolean {
        return Utl.isNullOrUndefined(value) || value === '';
    }

    static isNullOrUndefined(value: any): boolean {
        return value === undefined || value === null;
    }

    static reverseString(str: String) {
        return str.split('').reverse().join('');
    }
}
