import { Component, Input, OnInit, Output } from '@angular/core';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../services/storage.service';
import {BBSnackbarComponent} from '../bb-snackbar/bb-snackbar.component';

export interface MenuInterface {
    name: string;
    type: 'callback' | 'link';
    callback?: any;
    link?: string;
}

@Component({
    selector: 'app-bb-menu',
    templateUrl: './bb-menu.template.html'
})
export class BBMenuComponent implements OnInit {

    user: any = {
        firstName: '',
        lastName: '',
        email: '',
        verified: 'unverified'
    };

    _menuItems: MenuInterface[] = [
        {
            name: 'Profile',
            type: 'link',
            link: '/user/profile/general'
        },
        {
            name: 'Logout',
            type: 'callback',
            callback: this.logout.bind(this)
        }
    ];

    @Input() classNames: string = '';
    @Input() set menuItems(items: MenuInterface[]) {
        if (items.length) {
            this._menuItems = [...items];
        }
    }

    constructor(private authService: AuthService,
                private userService: UserService,
                private translate: TranslateService,
                private storage: StorageService,
                public snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        const isAuthenticated = this.storage.getItem('isLoggedin') === 'true';
        if (isAuthenticated) {
            this.getUser();
        }
    }

    getUser(): void {
        this.user.firstName = this.storage.getItem('firstName');
        this.user.lastName = this.storage.getItem('lastName');
        this.user.email = this.storage.getItem('email');
        this.user.verified = this.storage.getItem('verified');
    }

    logout(): void {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.snackBar.openFromComponent(BBSnackbarComponent, {
                        data: {type: 'ERROR', text: err}
                    });
                });
    }
}
