export const environment = {
    adultAge: 18,
    production: false,
    host: 'https://redesign.bitblinx.com/api',
    uploadHostUrl: 'https://redesign.bitblinx.com',
    socketUrl: 'wss://redesign.bitblinx.com/ws',
    appTitle: 'Bitblinx',
    authHeaderPrefix: 'sx',
    portalUrl: 'https://redesign.bitblinx.com',
    faqUrl: 'faq',
    blogUrl: 'blog',
    chartType: 'tradeview',
    announcementsUrl: '#announcements-section',
    missionUrl: '#mission-section',
    benefitsUrl: '#benefits-section',
    termsUrl: 'terms-conditions',
    priceMultiplicator: 10000000000,
    quantityMultiplicator: 1000000,
    reCaptchaSiteKey: '6Lfqxm4UAAAAAFUez_DrARHZwCPwe1b9Fqg65wOS',
    version: '1.0.35',
    mt5: {
        enabled: false
    },
    marginTrading: {
        enabled: false
    },
    beErrorLangKey: 'BACKEND_ERRORS',
    maxUploadSizeInMB: 3,
    unavailableCountries: ['UM', 'US'],
    cookie: {
        secure: true,
        domain: 'bitblinx.com'
    },
    firebase: {
        apiKey: 'AIzaSyBrYQKNZcTA52EthDVArt3Ok538uNkv8Dw',
        authDomain: 'bitblinx-ed045.firebaseapp.com',
        databaseURL: 'https://bitblinx-ed045.firebaseio.com',
        projectId: 'bitblinx-ed045',
        storageBucket: 'bitblinx-ed045.appspot.com',
        messagingSenderId: '989899833523',
        appId: '1:989899833523:web:443bebe9d8674e2b2070c6',
        measurementId: 'G-54HRXQY3Q0'
    }
};
