<div class="menu-wrapper" [ngClass]="classNames">
    <button mat-button class="menu-button"
            [matMenuTriggerFor]="menu">
        <span class="profile-data">
            <span class="avatar-circle">
                <span class="initials" *ngIf="user.firstName || user.lastName">
                    {{user.firstName ? user.firstName.charAt(0) : ''}}{{user.lastName ? user.lastName.charAt(0) : ''}}
                </span>
                <span class="initials" *ngIf="!user.firstName && !user.lastName">NN</span>
            </span>
            <span class="info-box">
                <span class="title" *ngIf="user.firstName || user.lastName">
                    {{user.firstName ? user.firstName : ''}}
                    {{user.lastName ? user.lastName : ''}}
                </span>
                <span class="title title-small" *ngIf="!user.firstName && !user.lastName">
                    {{user.email}}
                </span>
                <span class="title"
                      [ngClass]="user.verified === 'verified' ? 'verified' : 'unverified'">
                    {{user.verified}}
                </span>
            </span>
        </span>
        <span class="arrow-wrapper">
            <span class="arrow"></span>
        </span>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                *ngFor="let item of _menuItems">
            <span *ngIf="item.type === 'callback'" (click)="item.callback()">{{item.name}}</span>
            <span *ngIf="item.type === 'link'" [routerLink]="[item.link]">{{item.name}}</span>
        </button>
    </mat-menu>
</div>
