import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bb-toogle-button',
  templateUrl: './bb-toogle-button.component.html'
})
export class BBToogleButtonComponent implements OnInit {
  @Input() classNames: string;
  @Input() enabledIcon: string;
  @Input() disabledIcon: string;
  @Input() checked: boolean = false;

  @Output() onChange: EventEmitter <any> = new EventEmitter();
  
  iconName = '';
  checkEnabled = false;
  constructor() {
    
  }

  ngOnInit(): void {
    this.checkEnabled = this.checked;
    this.initIcon();
  }

  initIcon() {
    if (this.checkEnabled) {
      this.iconName = this.enabledIcon;
    } else {
      this.iconName = this.disabledIcon;
    }
  }
  
  onSelection(): void {
    this.checkEnabled = !this.checkEnabled;
    this.initIcon();
    this.onChange.emit(this.checkEnabled);
  }
}
