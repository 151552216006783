import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

export interface NavigationInterface {
    name: any;
    path: string;
    icon?: any;
    tooltipData?: any;
    exact?: boolean;
    activeList?: string[];
}
@Component({
    selector: 'app-bb-navigation',
    templateUrl: './bb-navigation.template.html'
})

export class BBNavigationComponent implements OnInit {
    private _routesSubject = new BehaviorSubject<NavigationInterface[]>([]);

    @Input() classNames: string;
    @Input() base: string;
    @Input() set links(data: NavigationInterface[]) {
        this._routesSubject.next(data);
    }

    get links(): NavigationInterface[] {
        return this._routesSubject.getValue();
    }

    _routes: NavigationInterface[] = [];
    _active: string;
    _selectedName: string;
    _url: string = '';

    constructor(public router: Router,
                private route: ActivatedRoute,
                public translate: TranslateService) {
        this.getCurrentUrl();
    }

    ngOnInit(): void {
        this._routesSubject.subscribe((data) => {
            this._routes = data && data.length ? [...data] : [];
        });
        if (this.router.url) {
            this.setCurrentRouteName(this.router.url);
        }
    }

    getCurrentUrl(): void {
        this.route.url.subscribe((data) => {
            const urlSegments = this.route.snapshot['_urlSegment'].segments;
            if (urlSegments && urlSegments.length) {
                this._url = '';
                for (const segment of urlSegments) {
                    this._url += `/${segment.path}`;
                }
            }
        });
    }

    setCurrentRouteName(path: string): void {
        for (const route of this._routes) {
            if (path.includes(route.path)) {
                this._selectedName = route.name;
                break;
            }
        }
    }

    onRouteSelect(link: any): void {
        this._selectedName = link.name;
        this.setCurrentRouteName(link.path);
    }

}
