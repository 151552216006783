import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {TranslateService} from '@ngx-translate/core';

export interface TooltipInterface {
    text?: string;
    textArray?: any[];
    title?: string;
    className?: string;
    overlayClassName?: string;
    show?: 'show' | 'hide';
}

@Component({
    selector: 'app-bb-tooltip',
    templateUrl: './bb-tooltip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('tooltip', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300, style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate(300, style({ opacity: 0 })),
            ]),
        ]),
    ],
})

export class BBTooltipComponent {
    @Input() tooltipData: TooltipInterface = {
        text: '',
        title: '',
        textArray: [],
        className: '',
        overlayClassName: '',
        show: 'show'
    };

    constructor(public translate: TranslateService) {

    }
}
