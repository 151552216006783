<div class="cards-group-wrapper">
    <mat-radio-group
        class="cards-radio-group"
        [ngClass]="classNames"
        [(ngModel)]="_selected">
        <mat-radio-button
            class="radio-card"
            *ngFor="let option of _options"
            [value]="option.value"
            (change)="onSelection($event)">
                <span class="card-check-indicator" [innerHTML]="checkIcon"></span>
                <span class="card-content-wrapper">
                    <span class="card-image-wrapper" *ngIf="option.iconUrl">
                        <img class="card-image" [src]="option.iconUrl" alt="">
                    </span>
                    <span class="card-icon-wrapper" *ngIf="option.icon" [innerHTML]="option.icon"></span>
                    <span class="card-text-wrapper">
                        <span class="card-label" *ngIf="option.label">{{option.label | translate}}</span>
                        <span class="card-text" *ngIf="option.text">{{option.text | translate}}</span>
                    </span>
                </span>
        </mat-radio-button>
    </mat-radio-group>
</div>
