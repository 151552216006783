import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-select-filter',
    templateUrl: './select-filter.component.html'
})
export class SelectFilterComponent implements OnInit, OnDestroy {
    @Input('itemsArray') itemsArray: any;
    @Input('translationString') translationString: string;
    @Output() changed = new EventEmitter();

    selectedSymbol: any;
    public itemsCtrl: FormControl = new FormControl();
    public itemsFilterCtrl: FormControl = new FormControl();
    public filteredItems: ReplaySubject<Symbol[]> = new ReplaySubject<Symbol[]>(1);
    private _onDestroy = new Subject<void>();

    constructor() {
    }

    ngOnInit() {
        this.itemsCtrl.setValue(this.itemsArray[0]);
        this.filteredItems.next(this.itemsArray.slice());

        this.itemsFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterSymbols();
                });
    }

    itemSelected(data: any) {
        this.changed.emit(data);
    }

    selectionChange() {
        this.itemSelected(this.itemsCtrl.value);

    }

    private filterSymbols() {
        if (!this.itemsArray) {
            return;
        }

        let search = this.itemsFilterCtrl.value;
        if (!search) {
            this.filteredItems.next(this.itemsArray.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredItems.next(
            this.itemsArray.filter(symbol => symbol.toLowerCase().indexOf(search) > -1)
        );
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

}
