import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bb-info-tooltip',
  templateUrl: './bb-info-tooltip.component.html'
})
export class BBInfoTooltipComponent implements OnInit {
    @Input() width: number;
    @Input() classNames: string;
    @Input() sign: string;
    @Input() tooltipData: any;

    constructor() { }

    ngOnInit(): void {
    }

}
