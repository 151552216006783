<div class="bb-snackbar-container" [ngClass]="'snackbar-'+data.type.toLowerCase()">
    <div class="bb-snackbar-icon-wrapper">
        <span [ngClass]="'bbi-circle-'+data.iconType.toLowerCase()"></span>
    </div>
    <div class="bb-snackbar-content-box">
        <p class="snackbar-title" *ngIf="data.title">{{data.title | translate}}</p>
        <div class="snackbar-text-box">
            <span class="snackbar-text" *ngFor="let data of infoData">
                <span *ngIf="data.title" class="title">{{ data.title.toUpperCase() | translate }}: </span>
                <span *ngIf="data.value">{{ data.value }}</span>
            </span>
        </div>
    </div>
    <button mat-button class="snackbar-close-btn btn btn-secondary btn-circle"
            (click)="close()">
        <span class="bbi-close-cross"></span>
    </button>
</div>
