import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AmountPipe} from './amount.pipe';

@Pipe({name: 'OrderInfo'})
export class OrderInfoPipe implements PipeTransform {

    constructor(
        private translate: TranslateService,
        private amountPipe: AmountPipe) {
    }

    transform(order: any, onlyStopPrice = false) {
        let string = '';
        const orderType = this.translate.instant('MARKET_VIEW.ORDER_TYPE_TOOLTIP');
        const orderSubType = this.translate.instant('MARKET_VIEW.ORDER_SUBTYPE_TOOLTIP');
        const stopPrice = this.translate.instant('MARKET_VIEW.ORDER_STOP_PRICE');

        if (onlyStopPrice) {
            string = `${string}${orderType}: ${order.type}\n`;
            string = `${string}${orderSubType}: ${order.subType}`;
        }

        if (order.extra && order.extra.stopPrice) {
            string = `${string}\n ${stopPrice}: ${this.amountPipe.transform(order.extra.stopPrice, order.symbol.split('/')[1])}`;
        }
        return string;
    }
}
