import {Routes} from '@angular/router';
import {GuestService} from './services/guest.service';
import {MainLayoutComponent} from './components/layouts/main-layout/main-layout.component';
import {AuthService} from './services/auth.service';
import {WithdrawActivateComponent} from './views/withdraw-activate/withdraw-activate.component';
import {VoucherMiddlewareComponent} from './views/sessions/voucher-middleware/voucher-middleware.component';

export const rootRouterConfig: Routes = [
    {
        path: 'withdraw',
        component: MainLayoutComponent,
        children: [
            {
                path: ':token',
                component: WithdrawActivateComponent
            }
        ],
        data: {}
    },
    {
        path: 'voucher-middleware',
        component: VoucherMiddlewareComponent
    },
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: () => import('./views/main/main.module').then(m => m.MainModule),
        data: {role: 'user'},
    },
    {
        path: 'sessions',
        component: MainLayoutComponent,
        canActivate: [GuestService],
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
    },
    {
        path: 'payment',
        component: MainLayoutComponent,
        loadChildren: () => import('./views/payment/payment.module').then(m => m.PaymentModule)
    },
    {
        path: ':lang/sessions',
        component: MainLayoutComponent,
        canActivate: [GuestService],
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
    },
    {
        path: 'user',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: {role: 'user'},
    },
    {
        path: 'documentation',
        loadChildren: () => import('./documentation/doc.module').then(m => m.DocModule),
    },
    {
        path: '**',
        redirectTo: 'sessions/404'
    }
];

