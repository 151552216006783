<div fxFlex="100" fxLayout="row wrap">
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start"
         class="table font-normal position-history-table">
        <div fxFlex="100" fxLayout="row wrap" class="table-header position-table-row"
             [ngClass]="{'admin-view': isAdmin}" fxHide.lt-md>
            <div class="table-col" *ngIf="showUserInfo">{{'POSITION_HISTORY.USER' | translate}}</div>
            <div class="table-col">{{'POSITION_HISTORY.TYPE' | translate}}</div>
            <div class="table-col">{{'POSITION_HISTORY.OPEN_DATE' | translate}}</div>
            <div class="table-col">{{'POSITION_HISTORY.CLOSE_DATE' | translate}}</div>
            <div class="table-col">{{'POSITION_HISTORY.AMOUNT' | translate}}</div>
            <div class="table-col">{{'POSITION_HISTORY.OPEN_PRICE' | translate}}</div>
            <div class="table-col">{{'POSITION_HISTORY.CLOSE_PRICE' | translate}}</div>
            <div class="table-col" *ngIf="showUserInfo">{{'POSITION_HISTORY.FEE' | translate}}</div>
            <div class="table-col">{{'POSITION_HISTORY.REALIZED_PL' | translate}}</div>
            <div class="table-col" *ngIf="isAdmin">{{'POSITION_HISTORY.SYMBOL' | translate}}</div>
        </div>

        <div fxFlex="100" fxLayout="column" class="table-body">
            <div class="table-row position-history-row" [ngClass]="{'admin-view': isAdmin}" fxLayout="row wrap" fxLayout.lt-md="column" *ngFor="let position of items">

                <div class="table-col" *ngIf="showUserInfo">
                    <b fxHide fxShow.lt-md="true">{{'POSITION_HISTORY.USER' | translate}}</b>
                    <span>{{position.email}}</span>
                </div>

                <div class="table-col">
                    <b fxHide fxShow.lt-md="true">{{'POSITION_HISTORY.TYPE' | translate}}</b>
                    <span [ngClass]="{'long': position.type === 'LONG', 'short': position.type === 'SHORT'}">{{position.type | uppercase}} (1:{{position.leverage}})</span>
                </div>

                <div class="table-col">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.OPEN_DATE" | translate}}</b>
                    {{position.created | date: 'dd/MM/yyyy HH:mm:ss'}}
                </div>

                <div class="table-col">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.CLOSE_DATE" | translate}}</b>
                    {{position.updated | date: 'dd/MM/yyyy HH:mm:ss'}}
                </div>

                <div class="table-col">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.AMOUNT" | translate}}</b>
                    {{position.quantity | quantity: position.symbol }}
                </div>

                <div class="table-col">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.OPEN_PRICE" | translate}}</b>
                    {{position.price | price: position.symbol }}
                </div>

                <div class="table-col">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.CLOSE_PRICE" | translate}}</b>
                    {{position.closePrice | price: position.symbol }}
                </div>

                <div *ngIf="showUserInfo" class="table-col">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.FEE" | translate}}</b>
                    {{position.fee | total: position.symbol }}
                </div>

                <div class="table-col">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.REALIZED_PL" | translate}}</b>
                    {{position.realizedPL | total: position.symbol }}
                </div>

                <div class="table-col" *ngIf="isAdmin">
                    <b fxHide fxShow.lt-md="true">{{"POSITION_HISTORY.SYMBOL" | translate}}</b>
                    {{position.symbol }}
                </div>

            </div>
        </div>
        <div fxFlex="100" fxLayoutAlign="center center" class="wallet orders"
             *ngIf="items && !items.length">
            <div fxFlex="0 1 auto">{{'TRANSACTIONS.NO_DATA_FOUND' | translate}}</div>
        </div>
    </div>

    <div fxFlex="100" class="pagination">
        <b class="current-page">{{'PAGE' | translate}}: {{page}}</b>
        <mat-paginator [length]="total"
                       [pageIndex]="page - 1"
                       [pageSize]="itemsPerPage"
                       [pageSizeOptions]="pageSizeOptions"
                       (page)="onPage($event)">
        </mat-paginator>
        <a class="current-page go-to-first-page" *ngIf="page > 1" (click)="toFirstPage()">
            {{'TO_FIRST_PAGE' | translate}}
        </a>
    </div>
</div>
