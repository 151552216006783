<ngx-file-drop class="uploader-drop-zone-box dotted-border-vertically"
               dropZoneLabel="Drop files here"
               (onFileDrop)="dropped($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

        <div class="uploader-header-box"
             *ngIf="!_files.length">
            <div class="uploader-cloud-box">
                <span class="material-icons icon-cloud">cloud_upload</span>
            </div>
            <div class="header-title-box">
                <h4 class="uploader-title">{{'PROFILE.DRAG_DROP_CLICK' | translate}}</h4>
                <p class="uploader-text">{{'PROFILE.ACCEPTED_IMAGE_FORMATS' | translate}}</p>
                <p class="uploader-text">{{'PROFILE.DOCS_MAX_COUNT' | translate : {'count': imagesCount} }}</p>
            </div>
        </div>

        <div class="uploader-body-box"
             *ngIf="_files.length">
            <div class="upload-image-all-data uploaded-doc-info-row"
                 *ngFor="let fileWrapper of _files; let i = index">

                <div class="doc-info-box">
                    <div class="doc-name" >
                        {{fileWrapper.file.name | excerpt: 25}}
                    </div>

                    <button mat-button class="btn btn-delete"
                            (click)="onRemove(i)">
                        <span class="icon-delete bbi-delete"></span>
                    </button>
                </div>

                <div class="doc-info-box">
                    <div class="uploading-progress"
                         *ngIf="fileWrapper">
                        <span class="progress-status"
                              *ngIf="!fileWrapper.processed">
                            {{'PROFILE.UPLOADING' | translate}}:
                            {{progress[fileWrapper.file.name].progress | async}}%
                        </span>
                        <span class="progress-status"
                              *ngIf="fileWrapper.processed">
                            {{'PROFILE.COMPLETED' | translate}}
                        </span>
                        <div *ngIf="!fileWrapper.processed"
                             class="progress-wrapper">
                            <mat-progress-bar
                                class="percent"
                                mode="determinate"
                                [value]="progress[fileWrapper.file.name].progress | async">
                            </mat-progress-bar>
                        </div>
                        <div *ngIf="fileWrapper.processed"
                             class="progress-wrapper">
                            <mat-progress-bar
                                class="percent"
                                mode="determinate"
                                [value]="100">
                            </mat-progress-bar>
                        </div>
                    </div>
                    <span class="doc-size">
                        {{(fileWrapper.file.size / 1024) | toFixed: 2}} kb
                    </span>
                </div>

            </div>
        </div>

        <div class="uploader-actions-box">
            <button class="btn btn-accent"
                    type="button"
                    (click)="openFileSelector()">
                <span *ngIf="!_files.length">{{'PROFILE.UPLOAD_FILE' | translate}}</span>
                <span *ngIf="_files.length">{{'PROFILE.UPLOAD_ANOTHER_FILE' | translate}}</span>
            </button>
        </div>

    </ng-template>
</ngx-file-drop>
<!-- Uncomment -->
<div class="uploaded-docs-box">
    <div *ngFor="let imgPath of addImagePath; let ind = index;"
         class="doc-box">
        <button mat-button (click)="onRemove(ind)"
                class="btn btn-secondary btn-circle doc-remove-btn">
            <span class="icon-delete bbi-delete"></span>
        </button>
        <img *ngIf="imgPath.fileType === 'image' && imgPath.data"
             class="uploaded-doc"
             src="{{imgPath.data}}" alt="">
        <img *ngIf="imgPath.fileType !== 'image'"
             class="uploaded-doc"
             src="assets/img/file-icon.svg" alt="">
    </div>
</div>
