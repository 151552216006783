import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'excerptCenter'})
export class ExcerptCenterPipe implements PipeTransform {
    transform(text: string, limit: number = 15) {
        if (text.length <= (limit * 2))
            return text;
        return text.substring(0, limit) + '...' + text.substring(text.length - limit, text.length);
    }
}
