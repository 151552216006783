import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { ThemeService } from '../../services/theme.service';
import {SlideToggleInterface} from '../bb-slide-toggle/bb-slide-toggle.component';

@Component({
  selector: 'app-bb-theme-color',
  templateUrl: './bb-theme-color.component.html'
})
export class BBThemeColorComponent implements OnInit {
  currentTheme: any = {};
  canChangeTheme: boolean = true;

    themes: SlideToggleInterface[] = [
        {
            icon: '<span class="icon-toggler bbi-moon"></span>',
            name: 'dark',
            value: 'dark'
        },
        {
            icon: '<span class="icon-toggler bbi-sun"></span>',
            name: 'light',
            value: 'light'
        }
    ];

  constructor(
    private storageService: StorageService,
    private themeService: ThemeService) {
  }

  ngOnInit(): void {
      this.themeService.hasFinished().subscribe((result) => {
          this.canChangeTheme = result;
      });
      this.themeService.getTheme().subscribe((result) => {
          const themeName = this.getCurrentTheme(result);

          for (const theme of this.themes) {
              if (theme.name === themeName) {
                  this.currentTheme = theme;
                  break;
              }
          }
          this.changeTheme(this.currentTheme.name);
      });
  }

  getCurrentTheme(theme): string {
      let themeName = 'light';
      const storageTheme = this.storageService.getItem('theme');

      if (theme && theme.newTheme) {
          themeName = theme.newTheme;
      }
      if (storageTheme && ['light', 'dark'].includes(storageTheme)) {
          themeName = storageTheme;
      }

      return themeName;
  }

  selectTheme(theme: any) {
      if (this.currentTheme.name === theme.name) {
          return;
      }
      this.changeTheme(theme.name, this.currentTheme.name);
      this.currentTheme = theme;
  }

  changeTheme(newClass: string, oldClass?: string) {
      if (!this.canChangeTheme) {
          return;
      }

      this.storageService.setItem('theme', newClass);
      const html = document.getElementsByTagName('html')[0];
      const newClassName = `theme-${newClass}`;
      const oldClassName = `theme-${oldClass}`;

      if (oldClass && html.classList.contains(oldClassName)) {
          html.classList.remove(oldClassName);
      }

      html.classList.add(newClassName);

      this.themeService.start();
      this.themeService.setTheme({
          newTheme: newClass,
          oldTheme: oldClass
      });
      // TODO check working this or not
      // if (!document.querySelector('iframe[id^="tradingview"]')) {
        this.themeService.end();
      // }
  }

  selectThemeMode(event) {
      const theme = this.themes.filter((el) => el.name === event);
      this.selectTheme(theme[0]);
  }

}
