<div class="stepper-container">
    <div class="step" *ngFor="let step of _steps; let i = index;">
        <button class="step-box" matRipple
                [matRippleColor]="_buttonsRippleColor"
                [routerLink]="step?.link"
                [disabled]="!(step.value <= _doneStep + 1) || (_doneStep >= _steps.length)">
            <span [appBBTooltip]="step?.tooltipData" class="step-icon-box"
                 [ngClass]="{'inactive': (!step.current && !step.isDone)}">
                <span class="step-icon-wrapper">
                    <span *ngIf="!step.isDone" class="step-text">{{step.value}}</span>
                    <span *ngIf="step.isDone" class="material-icons done-icon">done</span>
                </span>
            </span>
            <span class="step-label">{{step.label | translate}}</span>
        </button>
        <div *ngIf="i < _steps.length" class="step-horizontal-line"
             [ngClass]="{'done': step.isDone}"></div>
    </div>
</div>
