import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../services/storage.service';
import {BBBackdropComponent} from '../bb-backdrop/bb-backdrop.component';
import {DomService} from '../../services/dom.service';
import {fromEvent, timer, Subject} from 'rxjs';
import {map, debounce, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-bb-suggester',
  templateUrl: './bb-suggester.component.html'
})
export class BBSuggesterComponent implements OnInit {

    canSuggestMobile: boolean;
    showAppStoreLink: boolean = false;
    showGooglePlayLink: boolean = false;
    appStoreUrl: string = 'https://apps.apple.com/us/app/bitblinx-crypto-exchange/id1492248968';
    googlePlayUrl: string = 'https://play.google.com/store/apps/details?id=com.bitblinx.exchange';
    windowSubscription: any;
    windowUnsubscribe = new Subject<void>();

    constructor(public storage: StorageService,
                private domService: DomService) {
    }

    ngOnInit(): void {
        this.attachWindowListener();
        if (this.isSuggestMobile()) {
            this.storage.setItem('canSuggestMobile', 'true');
            this.windowSubscription.subscribe();

            if (window.innerWidth <= 599 && !this.canSuggestMobile) {
                this.openSuggestion();
            }
        }
        this.setMobileAppLInk();
    }

    attachWindowListener(): void {
        this.windowSubscription = fromEvent(window, 'resize')
            .pipe(
                takeUntil(this.windowUnsubscribe),
                debounce(() => timer(500)),
                map((event) => {
                    this.listenWindowMetrics();
                })
            );
    }

    listenWindowMetrics(): void {
        if (window.innerWidth <= 599 && !this.canSuggestMobile) {
            this.openSuggestion();
        }
        if (window.innerWidth > 599) {
            this.closeSuggestion();
        }
    }

    isSuggestMobile(): boolean {
        const suggestApp = this.storage.getItem('canSuggestMobile');
        return !suggestApp || suggestApp === 'true';
    }

    setMobileAppLInk(): void {
        const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (isiOS) {
            this.showAppStoreLink = true;
        } else {
            this.showGooglePlayLink = true;
        }
    }

    openSuggestion(): void {
        this.domService.append(BBBackdropComponent);
        this.canSuggestMobile = true;
    }

    closeSuggestion(): void {
        this.domService.detach();
        this.canSuggestMobile = false;
    }

    removeSuggestion(): void {
        this.closeSuggestion();
        this.storage.setItem('canSuggestMobile', 'false');
        this.windowUnsubscribe.next();
        this.windowUnsubscribe.complete();
    }

}
