import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fillOrders' })

export class FillOrderPipe implements PipeTransform {
  transform(value, minSize) {
    const newValue = [...value];

    const diff = minSize - value.length;
    if (diff > 0) {
        for (let i = 0; i < diff; ++i) {
          const order = {
            price: null,
            quantity: null,
            total: null
          };

          newValue.push(order);
        }
    }
    
    return newValue;
  }
}