import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {StorageService} from './storage.service';

class Themes {
    newTheme: string;
    oldTheme: string;
}

@Injectable()
export class ThemeService {
    theme: BehaviorSubject<any> = new BehaviorSubject({});
    themeState = this.theme.asObservable();
    ready: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(private storageService: StorageService) {

    }

    setTheme(themes: Themes) {
        this.changeTheme(themes);
        this.theme.next(themes);
    }

    getTheme(): Observable<Themes> {
        return this.themeState;
    }

    start() {
        this.ready.next(false);
    }

    end() {
        this.ready.next(true);
    }

    hasFinished(): Observable<any> {
        return this.ready;
    }

    changeTheme(themes: Themes) {
        this.storageService.setItem('theme', themes.newTheme);
        const root = document.documentElement;
        const newClassName = `theme-${themes.newTheme}`;
        const oldClassName = `theme-${themes.oldTheme}`;

        if (themes.oldTheme && root.classList.contains(oldClassName)) {
            root.classList.remove(oldClassName);
        }

        root.classList.add(newClassName);
    }
}
