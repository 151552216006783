import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TableBaseComponent} from '../table-base/table-base.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {StorageService} from '../../services/storage.service';

@Component({
    selector: 'app-position-table',
    templateUrl: 'position-table.template.html'
})
export class PositionTableComponent extends TableBaseComponent implements OnInit {
    @Input()
    public showUserInfo = false;
    isAdmin: boolean;

    constructor(public translate: TranslateService,
                public storage: StorageService) {
        super();
    }

    ngOnInit() {
        this.isAdmin = this.storage.getItem('role') === 'admin';
    }
}
