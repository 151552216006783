import {Component, Input, OnInit} from '@angular/core';
import {MaintenanceService} from '../../services/maintenance.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {StorageService} from '../../services/storage.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent implements OnInit {
    @Input('userRole') userRole: string;
    public maintenancePage = false;
    public hideNotification = false;

    public maintenance: any = {
        maintenance: false,
        maintenanceType: '',
        maintenanceMessage: '',
        status: ''
    };

    constructor(private maintenanceService: MaintenanceService,
                private router: Router,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.getMaintenanceStatus();
        this.maintenancePage = this.router.routerState.snapshot.url === '/sessions/maintenance';

        this.maintenanceService.maintenanceChange.subscribe(
            (maintenance) => {
                this.getMaintenanceStatus();
            }
        );
    }

    getMaintenanceStatus() {
        this.maintenanceService.getMaintenance().subscribe(
            (result) => {
                this.maintenance = result.result;
                this.maintenanceService.maintenanceData.next(this.maintenance);

                if (this.maintenance.maintenanceType === 'maintenance') {
                    if (this.userRole &&
                        !['admin', 'superadmin', 'support-admin', 'accountant'].includes(this.userRole)) {
                        this.authService.clearStorage();
                        this.router.navigate(['/sessions/maintenance']);
                    }
                }
            },
            (err) => {

            }
        );
    }

    closeNotification() {
        this.maintenance.status = 'finished';
        this.maintenanceService.maintenanceData.next(this.maintenance);
        this.hideNotification = true;
    }
}
