<div class="maintenance-box notification"
     *ngIf="maintenance.maintenance && !maintenancePage && !hideNotification">

    <div class="maintenance-icon-wrapper">
        <span class="bbi-circle-info"></span>
    </div>

    <div class="ticker-wrap">
        <div class="ticker">
            <div class="ticker-item">
                {{maintenance.maintenanceMessage}}
            </div>
        </div>
    </div>

    <div class="maintenance-button-wrapper">
        <button mat-button class="snackbar-close-btn btn btn-secondary btn-circle"
                *ngIf="maintenance.maintenanceType === 'notification'"
                (click)="closeNotification()">
            <span class="bbi-close-cross"></span>
        </button>
    </div>
</div>
