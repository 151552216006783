import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'walletFilter'})
export class WalletFilterPipe implements PipeTransform {
    transform(wallets: any[], currency, showOnlyWithResources = false): any {
        if (!wallets) {
            return wallets;
        }

        let copyWallets = [...wallets];
        if (currency !== '') {
            copyWallets = copyWallets.filter(wallet => {
                if (wallet.currency.toUpperCase().indexOf(currency.toUpperCase()) !== -1 ||
                    wallet.name.toUpperCase().indexOf(currency.toUpperCase()) !== -1) {
                    return wallet;
                }
            });
        }
        
        if (showOnlyWithResources) {
            copyWallets = copyWallets.filter(wallet => {
                if (wallet.available > 0 || wallet.total > 0) {
                    return wallet;
                }
            });
        }
    
        return copyWallets;
       
    }
}
