import {Component, EventEmitter, Input, Output, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {forkJoin, Observable} from 'rxjs';
import {FileWrapper, UploadService} from '../../services/upload.service';
import {TranslateService} from '@ngx-translate/core';
import {BBSnackbarComponent} from '../bb-snackbar/bb-snackbar.component';


@Component({
    selector: 'app-upload',
    templateUrl: 'upload.component.html'
})
export class UploadComponent implements OnChanges {
    env: any = environment;
    @Input() public fileType: string = 'image';
    @Input() public uploadButtonName;
    @Input() public entityType: string = '';
    @Input() public imagesCount: number = 10;
    @Input() public dim: number = 50;
    @Output() files: EventEmitter<string[]> = new EventEmitter();

    public _files: FileWrapper[] = [];
    progress: any;
    uploading = false;
    doneDisabled = true;
    public addImagePath: any[] = [];

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                public uploadService: UploadService,
                public translate: TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('imageUrls') && changes.imageUrls.currentValue) {
            this.addImagePath = changes.imageUrls.currentValue;
        }
        if (changes.hasOwnProperty('imagesCount') && changes.imagesCount.currentValue) {
            this.imagesCount = changes.imagesCount.currentValue;
        }
    }

    @Input()
    public set imageUrls(imagesData: any[]) {
        this.setImageUrls(imagesData);
    }

    public addImageUrl(imgData) {
        for (const image of this.addImagePath) {
            if (imgData.dir === image.dir && imgData.name === image.name) {
                image.data = imgData.data;
                return;
            }
        }
        this.addImagePath.unshift(imgData);
    }

    emitUploadedFiles(): void {
        if (this.addImagePath instanceof Array) {
            const newList = [...this.addImagePath];
            this.addImagePath = newList;
            this.files.emit(newList);
        }
    }

    getImageFullPath(imgData) {
        if (!imgData) {
            return null;
        }
        if (imgData.data) {
            return imgData.data;
        }
        const dimensionOrOriginal = (this.fileType === 'image' ? `${this.dim}x${this.dim}` : 'original');
        return `${this.env.uploadHostUrl}/${this.entityType}/${imgData.dir}/${dimensionOrOriginal}/${imgData.name}`;
    }

    protected setImageUrls(imagesData: any[]) {
        if (!imagesData) {
            imagesData = [];
        }
        this.addImagePath = imagesData;
    }


    /* Uploader dialog part */


    public dropped(files: NgxFileDropEntry[]) {
        let i = 0;
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    ++i;
                    let error = false;
                    if (file.size / (1024 * 1024) > this.env.maxUploadSizeInMB) {
                        this.snackBar.openFromComponent(BBSnackbarComponent, {
                                data: {
                                    type: 'ERROR',
                                    text: this.translate.instant('UPLOADER.FILE_SIZE_LIMIT',
                                        {name: file.name, size: this.env.maxUploadSizeInMB})
                                }
                            });
                        error = true;
                    }

                    if (this.addImagePath.length < this.imagesCount) {
                        if (['application/jpeg', 'image/jpeg', 'image/png', 'text/plain', 'application/pdf'].includes(file.type)) {

                            if (error === false) {
                                this._files.unshift(new FileWrapper(file));
                            }

                        } else {
                            this.snackBar.openFromComponent(BBSnackbarComponent, {
                                data:  {
                                    type: 'ERROR',
                                    text: this.translate.instant('FILE_TYPE_ERROR')
                                }
                            });
                        }
                    } else {
                        this.snackBar
                            .openFromComponent(BBSnackbarComponent, {
                                data: {
                                    type: 'ERROR',
                                    text: this.translate.instant('UPLOADER.FILE_COUNT_LIMIT',
                                        {count: this.imagesCount})
                                }
                            });
                    }

                    if (i === files.length) {
                        this.onUpload();
                    }
                });

            } else {
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }

    }

    onUpload() {
        this.uploading = true;
        for (let i = this.addImagePath.length - 1; i >= 0; --i) {
            for (const fw of this._files) {
                if (this.addImagePath[i].index === fw.index) {
                    this.addImagePath.splice(i, 1);
                    break;
                }
            }
        }

        this.progress = this.uploadService.upload('documents', this._files,
            (result) => {
            const reader = new FileReader();
            reader.readAsDataURL(result.fileWrapper.file);

            reader.onload = () => {
                const base64Img = reader.result;

                const imgData = {
                    dir: result.responseBody.result.dir,
                    name: result.responseBody.result.name,
                    fileType: result.responseBody.result.fileType,
                    data: base64Img,
                    fileIndex: result.fileWrapper.index
                };

                this.addImagePath.unshift(imgData);
                result.fileWrapper.processed = true;

                this.emitUploadedFiles();
            };
        });

        const allProgressObservables: Observable<number>[] = Object.values(this.progress).map((obj: any) => obj.progress);

        forkJoin(allProgressObservables).subscribe(end => {
            this.uploading = false;
        });

        this.doneDisabled = false;
    }

    onRemove(index) {
        this.addImagePath.splice(index, 1);
        this._files.splice(index, 1);
        this.emitUploadedFiles();
    }
}
