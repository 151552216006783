import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { OptionInterface } from '../../components/bb-radio-group/bb-radio-group.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bb-pair-selector',
  templateUrl: './bb-pair-selector.component.html'
})
export class BBPairSelectorComponent implements OnInit {
  @Input() selectedSymbol: any;
  @Input() symbols: any;
  @Input() pairPrices: any;
  @Input() pairPriceChange: any;
  @Input() pairVolume: any;
  @Output() onPairSelect: EventEmitter<any> = new EventEmitter();

  opened = false;

  userFavoritePairs = [];
  quoteArray: string[] = ['ANY'];
  pairFiltersUpdate = false;
  pairFilters = {
    search: '',
    quote: 'ANY',
    sortVol: 'max-min',
    sortSymbol: '',
    sortH24: '',
    activeFav: false,
    favorites: []
  };

  quteCurrency: OptionInterface[] = [
    { name: 'All', value: 'ANY' },
    { name: 'BTC', value: 'BTC' },
    { name: 'ETH', value: 'ETH' },
    { name: 'EUR', value: 'EUR' }
  ];

  constructor(private userService: UserService,
    private router: Router,
    private storage: StorageService) {

  }

  ngOnInit(): void {
    if (this.storage.getItem('favoritePairs') !== 'undefined') {
      const localUserFavorites = this.storage.getItem('favoritePairs');
      if (localUserFavorites) {
        this.userFavoritePairs = this.storage.getItem('favoritePairs').split(',');
      }
    }

    for (const symbol of this.symbols) {
      if (!this.quoteArray.includes(symbol.quote)) {
        this.quoteArray.push(symbol.quote);
      }
    }
  }

  selectPair(item) {
    this.opened = false;
    this.onPairSelect.emit(item);
  }

  onQuteChange(value) {
    this.pairFilters.quote = value;
    this.updatePairPipe();
  }

  updatePairPipe() {
    this.pairFiltersUpdate = true;
    setTimeout(() => {
      this.pairFiltersUpdate = false;
    }, 100);
  }

  filterFavorites(value) {
    this.pairFilters.activeFav = value;
    if (!this.pairFilters.activeFav) {
      this.pairFilters.favorites = [];
    } else {
      this.pairFilters.favorites = this.userFavoritePairs;
    }
    this.updatePairPipe();
  }

  addPairToFavorite(symbol) {
    const isAuthenticated = this.storage.getItem('isLoggedin') === 'true';

    if (!isAuthenticated) {
      this.router.navigate(['/sessions/signin']);
      return;
    }

    if (this.userFavoritePairs.includes(symbol)) {
      const index = this.userFavoritePairs.indexOf(symbol);
      this.userFavoritePairs.splice(index, 1);
    } else {
      this.userFavoritePairs.push(symbol);
    }
    this.storage.setItem('favoritePairs', this.userFavoritePairs.join(','));
    this.userService.updateUserFavorites(this.userFavoritePairs).subscribe();
    this.updatePairPipe();
  }

  sortPair() {
    switch (this.pairFilters.sortSymbol) {
      case '':
        this.pairFilters.sortSymbol = 'a-z';
        this.pairFilters.sortVol = '';
        this.pairFilters.sortH24 = '';
        break;
      case 'a-z':
        this.pairFilters.sortSymbol = 'z-a';
        this.pairFilters.sortVol = '';
        this.pairFilters.sortH24 = '';
        break;
      default:
        this.pairFilters.sortSymbol = '';
    }

    this.updatePairPipe();
  }

  sortH24() {
    switch (this.pairFilters.sortH24) {
      case '':
        this.pairFilters.sortH24 = 'max-min';
        this.pairFilters.sortSymbol = '';
        this.pairFilters.sortVol = '';
        break;
      case 'max-min':
        this.pairFilters.sortH24 = 'min-max';
        this.pairFilters.sortSymbol = '';
        this.pairFilters.sortVol = '';
        break;
      default:
        this.pairFilters.sortH24 = '';
    }

    this.updatePairPipe();
  }

  sortVol() {
    switch (this.pairFilters.sortVol) {
      case '':
        this.pairFilters.sortVol = 'max-min';
        this.pairFilters.sortSymbol = '';
        this.pairFilters.sortH24 = '';
        break;
      case 'max-min':
        this.pairFilters.sortVol = 'min-max';
        this.pairFilters.sortSymbol = '';
        this.pairFilters.sortH24 = '';
        break;
      default:
        this.pairFilters.sortVol = '';
    }

    this.updatePairPipe();
  }

  clickOutsideSelector() {
    if (this.opened === false) {
      return;
    }

    this.opened = false;
  }

}
