<div class="info-container">
    <div class="info-bar-wrapper">
        <div class="info-bar" [ngClass]="className">
            <div class="info-icon-box">
                <app-bb-info-tooltip
                    class="info-bar-triangle-box"
                    [sign]="'!'"
                    [classNames]="'info-triangle info-main'">
                </app-bb-info-tooltip>
            </div>
            <div class="info-content-box">
                <p class="info-title">{{title | translate}}</p>
                <p class="info-text">{{text}}</p>
            </div>
        </div>
    </div>
</div>
