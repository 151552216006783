import {Component} from '@angular/core';
import {UploadComponent} from './upload.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StorageService} from '../../services/storage.service';
import {UserService} from '../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {UploadService} from '../../services/upload.service';
import {ActivatedRoute} from '@angular/router';
import {BBSnackbarComponent} from '../bb-snackbar/bb-snackbar.component';

@Component({
    selector: 'app-user-document-upload',
    templateUrl: 'upload.component.html'
})
export class UserDocumentUploadComponent extends UploadComponent {
    private userId: string;

    constructor(public dialog: MatDialog,
                public translate: TranslateService,
                private storage: StorageService,
                private userService: UserService,
                private route: ActivatedRoute,
                public snackBar: MatSnackBar,
                public uploadService: UploadService) {
        super(dialog, snackBar, uploadService, translate);

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.userId = params['id'];
            } else {
                this.userId = this.storage.getItem('userId');
            }
        });
    }

    protected setImageUrls(imagesData: any[]) {
        if (!imagesData) {
            return super.setImageUrls([]);
        }
        super.setImageUrls(imagesData);
        for (const imageData of imagesData) {
            this.load(imageData.dir, imageData.name, imageData.fileType);
        }
    }

    private load(dir, name, fileType) {
        this.userService.getUserDocument(this.userId, fileType === 'image' ? `${this.dim}x${this.dim}` : 'original', dir, name)
            .subscribe(
                (result) => {
                    if (result.status) {
                        super.addImageUrl({data: result.result, dir: dir, name: name, fileType: fileType});
                    }
                },
                (err) => {
                    this.snackBar.openFromComponent(BBSnackbarComponent, {
                        data: {type: 'ERROR', text: err}
                    });
                });
    }
}
