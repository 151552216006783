import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-bb-backdrop',
  templateUrl: './bb-backdrop.component.html'
})

export class BBBackdropComponent implements OnInit, OnDestroy {
    open: boolean = false;

    constructor() { }

    ngOnInit(): void {
        this.open = true;
        document.body.classList.add('no-scroll');
    }

    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
    }

}
