<div class="pro-container">
    <mat-drawer-container class="drawer-container"
                          [hasBackdrop]="backdropEnabled">
        <mat-drawer #drawer class="sidenav-box"
                    [mode]="sidebarMode"
                    [opened]="sidebarOpened">
            <div class="sidenav-menu-container">
                <div class="sidenav-top-wrapper">
                    <div class="sidenav-heading-wrapper">
                        <div class="menu-logo-wrapper">
                            <img class="menu-logo"
                                 routerLink="/"
                                 src="../../../../assets/img/bb_logo.svg" alt="">
                            <div class="menu-toggler-wrapper">
                                <button mat-raised-button
                                        class="hamburger-button"
                                        (click)="drawer.toggle()">
                                    <span class="hamburger"></span>
                                    <span class="hamburger"></span>
                                    <span class="hamburger"></span>
                                </button>
                            </div>
                        </div>

                        <div *ngIf="!isAuthenticated" class="sidenav-theme-change-wrapper">
                            <app-bb-theme-color></app-bb-theme-color>
                        </div>

                        <div *ngIf="isAuthenticated" class="sidenav-profile-menu-wrapper">
                            <app-bb-menu [classNames]="'secondary-menu hide-info-sm'"></app-bb-menu>
                        </div>
                    </div>

                    <div *ngIf="items.length" class="nav-menu">
                        <div class="navigation-box">
                            <div class="nav-item"
                                 [ngClass]="item?.className"
                                 *ngFor="let item of items; let i = index;"
                                 (click)="onNavigate(i)"
                                 [routerLink]="[item.link]"
                                 [routerLinkActiveOptions]="{ exact: item.exact }"
                                 [appBBTooltip]="item.tooltipData"
                                 routerLinkActive="active">
                                <div class="nav-item-icon">
                                    <span *ngIf="item.icon" class="nav-icon" [class]="item.icon"></span>
                                    <span *ngIf="item.materialIcon" class="material-icons">
                                        {{item.materialIcon}}
                                    </span>
                                </div>
                                <span class="nav-item-title">
                                    {{item.name | translate}}
                                </span>
                            </div>
                        </div>
                        <div class="nav-track">
                            <div class="nav-track-bar"
                                 *ngIf="!isProfilePages"
                                 [ngStyle]="{'top.px': trackBarPos * 60}">
                            </div>
                        </div>
                    </div>
                </div>


                <div class="info-menu">
                    <div class="language-toggler-wrapper">
                        <button mat-button class="language-toggler"
                                [matMenuTriggerFor]="languages">
                            <span class="lang-img-wrapper">
                                <img class="lang-img" [src]="currentLang.img" alt="">
                            </span>
                            <span class="lang-name">{{currentLang.name}}</span>
                        </button>
                        <mat-menu #languages="matMenu" [overlapTrigger]="false">
                            <button mat-menu-item class="lang-select"
                                    *ngFor="let lang of suggestedLangs"
                                    (click)="onLangSelect(lang)">
                                <span class="lang-img-wrapper">
                                    <img class="lang-img" [src]="lang.img" alt="">
                                </span>
                                <span class="lang-select-name">{{lang.name}}</span>
                            </button>
                        </mat-menu>
                    </div>
                    <div class="nav-item info-item">
                        <a class="info-icon-wrapper" target="_blank"
                           href="https://bitblinx.freshdesk.com/support/home"
                           [appBBTooltip]="supportTooltipData">
                            <div class="nav-item-icon">
                                <span class="nav-icon bbi-support"></span>
                            </div>
                            <span class="nav-item-title">
                                {{'USER.SUPPORT'| translate}}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <app-maintenance [userRole]="userRole"></app-maintenance>

            <div class="content" [ngClass]="{'margin-at-top': isMaintenance}">
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
