<div class="radio-group-wrapper">
    <mat-radio-group
        class="radio-group"
        [ngClass]="classNames"
        [(ngModel)]="_selected">
        <mat-radio-button
            class="radio-button"
            *ngFor="let option of _options"
            [value]="option.value"
            (change)="onSelection($event)">
                <span *ngIf="labelType === 'value'">{{option.name | translate}}</span>
                <span *ngIf="labelType === 'icon'" [innerHtml]="option.name"></span>
        </mat-radio-button>
    </mat-radio-group>
</div>
