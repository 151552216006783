import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-reason-disapprove',
    templateUrl: 'disapprove-modal.template.html'
})
export class ReasonComponent {
    env: any = environment;
    reasonText: string = '';

    constructor(public dialogRef: MatDialogRef<ReasonComponent>,
                public snackBar: MatSnackBar,
                public translate: TranslateService) {

    }

    cancel() {
        this.dialogRef.close({result: false});
    }

    confirm() {
        this.dialogRef.close({result: true, message: this.reasonText});
    }
}
