import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {NgxFileDropModule} from 'ngx-file-drop';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component';
import { ProComponent } from './layouts/pro/pro.component';
import { BasicComponent } from './layouts/basic/basic.component';

import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';

import {ClipboardDirective} from '../directives/clipboard.directive';
import {ReasonComponent} from './disapprove-modal/disapprove-modal.component';
import {UserHeaderComponent} from './headers/user-header/user-header.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {ApproveCancelComponent} from './approve-cancel/approve-cancel.component';
import {CommonPipesModule} from '../pipes/common/common-pipes.module';
import {ChartsModule} from 'ng2-charts';
import {ResponsiveMenuComponent} from './responsive-menu/responsive-menu.component';
import {BarChartComponent} from './chart/bar-chart.component';
import {LineChartComponent} from './chart/line-chart.component';
import {PieChartComponent} from './chart/pie-chart.component';
import {PositionTableComponent} from './position-table/position-table.component';
import {TableBaseComponent} from './table-base/table-base.component';
import {ActivePositionsTableComponent} from './active-positions-table/active-positions-table.component';
import {SelectFilterComponent} from './select-filter/select-filter.component';
import {ProvideDestinationTagComponent} from './provide-destination-tag/provide-destination-tag.component';
import {AmountFormatterDirective} from '../directives/amount.directive';
import {PriceFormatterDirective} from '../directives/price.directive';
import {QuantityFormatterDirective} from '../directives/quantity.directive';
import {AutofocusDirective} from '../directives/autofocus.directive';

import {TvChartContainerComponent} from './tv-chart-container/tv-chart-container.component';
import {RedeemCgiftComponent} from './redeem-cgift/redeem-cgift.component';
import {ClickOutsideDirective} from '../directives/click-outside.directive';
import {CheckByProviderComponent} from './check-by-provider-modal/check-by-provider-modal.component';
import {MultipleSelectComponent} from './multiple-select/multiple-select.component';
import {BBRadioGroupComponent} from './bb-radio-group/bb-radio-group.component';
import {BBMenuComponent} from './bb-menu/bb-menu.component';
import {BBNavigationComponent} from './bb-navigation/bb-navigation.component';
import {BBThemeColorComponent} from './bb-theme-color/bb-theme-color.component';
import {BBSlideToggleComponent} from './bb-slide-toggle/bb-slide-toggle.component';
import {BBSTepperComponent} from './bb-stepper/bb-stepper.component';
import {BBPairSelectorComponent} from './bb-pair-selector/bb-pair-selector.component';
import {BBToogleButtonComponent} from './bb-toogle-button/bb-toogle-button.component';
import {BBCardGroupComponent} from './bb-card-group/bb-card-group.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SessionComponent } from './layouts/session/session.component';
import {UploadComponent} from './uploader/upload.component';
import {UserDocumentUploadComponent} from './uploader/user-document-upload.component';
import { BBInfoTooltipComponent } from './bb-info-tooltip/bb-info-tooltip.component';
import {PasswordStrengthComponent} from './password-strength/password-strength.component';
import { BBTooltipDirective } from './directives/bb-tooltip/bb-tooltip.directive';
import {BBTooltipComponent} from './directives/bb-tooltip/bb-tooltip.component';
import { OverlayModule } from '@angular/cdk/overlay';
import {BBSnackbarComponent} from './bb-snackbar/bb-snackbar.component';
import { BBControlTopPanelComponent } from './bb-control-top-panel/bb-control-top-panel.component';
import {BBInfoBarComponent} from './bb-info-bar/bb-info-bar.component';
import {BBSuggesterComponent} from './bb-suggester/bb-suggester.component';
import { BBBackdropComponent } from './bb-backdrop/bb-backdrop.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        MatMenuModule,
        MatSnackBarModule,
        MatButtonModule,
        MatRadioModule,
        MatRippleModule,
        MatChipsModule,
        MatCheckboxModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        NgxFileDropModule,
        NgxMatSelectSearchModule,
        HttpClientModule,
        TranslateModule,
        CommonPipesModule,
        ChartsModule,
        NgScrollbarModule,
        OverlayModule
    ],
    declarations: [
        MainLayoutComponent,
        BasicComponent,
        ProComponent,
        ClipboardDirective,
        ClickOutsideDirective,
        AutofocusDirective,
        ReasonComponent,
        UserHeaderComponent,
        MaintenanceComponent,
        ApproveCancelComponent,
        ResponsiveMenuComponent,
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        TableBaseComponent,
        PositionTableComponent,
        ActivePositionsTableComponent,
        SelectFilterComponent,
        ProvideDestinationTagComponent,
        AmountFormatterDirective,
        PriceFormatterDirective,
        QuantityFormatterDirective,
        TvChartContainerComponent,
        RedeemCgiftComponent,
        CheckByProviderComponent,
        MultipleSelectComponent,
        BBRadioGroupComponent,
        BBMenuComponent,
        BBNavigationComponent,
        BBThemeColorComponent,
        BBSlideToggleComponent,
        BBSTepperComponent,
        BBPairSelectorComponent,
        BBToogleButtonComponent,
        SessionComponent,
        BBCardGroupComponent,
        UploadComponent,
        UserDocumentUploadComponent,
        BBInfoTooltipComponent,
        PasswordStrengthComponent,
        BBTooltipDirective,
        BBTooltipComponent,
        BBSnackbarComponent,
        BBControlTopPanelComponent,
        BBInfoBarComponent,
        BBSuggesterComponent,
        BBBackdropComponent,
    ],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                panelClass: 'bb-snackbar-panel',
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 5000
            }
        }
    ],
    entryComponents: [
        ReasonComponent,
        ApproveCancelComponent,
        ProvideDestinationTagComponent,
        RedeemCgiftComponent,
        CheckByProviderComponent,
        BBTooltipComponent,
        BBSnackbarComponent,
        BBBackdropComponent,
    ],
    exports: [
        ClipboardDirective,
        ClickOutsideDirective,
        AutofocusDirective,
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        TableBaseComponent,
        PositionTableComponent,
        ActivePositionsTableComponent,
        SelectFilterComponent,
        AmountFormatterDirective,
        PriceFormatterDirective,
        QuantityFormatterDirective,
        TvChartContainerComponent,
        ResponsiveMenuComponent,
        MultipleSelectComponent,
        BBRadioGroupComponent,
        BBMenuComponent,
        BBNavigationComponent,
        BBThemeColorComponent,
        BBSlideToggleComponent,
        BBSTepperComponent,
        BBPairSelectorComponent,
        BBToogleButtonComponent,
        BBCardGroupComponent,
        BBInfoTooltipComponent,
        UploadComponent,
        UserDocumentUploadComponent,
        PasswordStrengthComponent,
        BBTooltipDirective,
        BBControlTopPanelComponent,
        BBInfoBarComponent,
        BBSuggesterComponent,
        BBBackdropComponent,
        BBTooltipComponent,
    ]
})
export class AppCommonModule {
}
