import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

export interface OptionInterface {
    name: any;
    value: any;
}

@Component({
    selector: 'app-bb-radio-group',
    templateUrl: './bb-radio-group.template.html'
})

export class BBRadioGroupComponent implements OnInit {
    private _optionsSubject = new BehaviorSubject<OptionInterface[]>([]);
    private _selectedSubject = new BehaviorSubject<any>(null);

    @Input() classNames: string;
    @Input() labelType: 'icon' | 'value' = 'value';
    @Input() set options(data: OptionInterface[]) {
        this._optionsSubject.next(data);
    }
    @Input() set selected(data: any) {
        this._selectedSubject.next(data);
    }
    @Output() onSelect: EventEmitter <any> = new EventEmitter();

    get options(): OptionInterface[] {
        return this._optionsSubject.getValue();
    }

    get selected(): any {
        return this._selectedSubject.getValue();
    }

    _options: OptionInterface[] = [];
    _selected: OptionInterface;

    constructor(public translate: TranslateService) {
    }

    ngOnInit(): void {
        this._optionsSubject.subscribe((data) => {
            this._options = data && data.length ? [...data] : [];
        });

        this._selectedSubject.subscribe((data) => {
            this._selected = data;
            if (!this._selected) {
                this._selected = this._options[0].value;
            }
        });

    }

    onSelection(option: any): void {
        this.onSelect.emit(option.value);
    }
}
