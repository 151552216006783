import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TableBaseComponent} from '../table-base/table-base.component';
import {MarginService} from '../../services/margin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {BBSnackbarComponent} from '../bb-snackbar/bb-snackbar.component';

@Component({
    selector: 'app-active-positions-table',
    templateUrl: 'active-positions-table.template.html'
})
export class ActivePositionsTableComponent extends TableBaseComponent {
    @Input()
    public showUserInfo = false;
    @Output() positionClosed: EventEmitter<any> = new EventEmitter();

    constructor(public translate: TranslateService,
                public marginService: MarginService,
                public snackBar: MatSnackBar) {
        super();
    }

    closePosition(position: any) {
        this.marginService.closePosition(position.id).subscribe(
            (result) => {
                this.snackBar.openFromComponent(
                    BBSnackbarComponent, {
                        data: {
                            type: 'INFO',
                            title: this.translate.instant('POSITION_CLOSED'),
                            id: position.positionID
                        }
                    });
                this.positionClosed.emit();
            },
            (err) => {
                this.snackBar.openFromComponent(BBSnackbarComponent, {data: {type: 'ERROR', text: err}});
            }
        );
    }
}
